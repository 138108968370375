/* eslint-disable */
import React, { useState } from "react";
import useLocalStorage from "../../../utils/hooks/useLocalStorage";
import { questions } from "./data";
import UploadImage from "./UploadImage2";

const Questions = ({
	current,
	section,
}) => {
	
	const GetSubQuestions = ({ s, question}) => {
		return question.sub ? (
			<div className="sub-query">
				<div className="question">{s.sub}</div>
			</div>
		) : null;
	};

	const GetQuestions = ({ current }) => {
		let s = questions[section];
		let q = s.questions[current];
		return (
			<div
				key={"questioncontainer" + current}
				id={"questioncontainer" + current}
				className={"questioncontainer"}
			>
				<div key={"section" + section}>
					<div
						className="question-content"
						key={"questionc" + section + current}
						id={"questionc" + section + current}
					>
						<div className="question w-100 text-center">
							<span className="question-no">{current + 1}</span>
							<strong className="body">{q.question}</strong>
						</div>
						<GetAnswers
							key={"questionb" + section + current}
							s={s.questions}
							question={q}
							number={current}
						/>
						<GetSubQuestions
							key={"questionsub" + section + current}
							s={s.questions}
							question={q}
							number={current}
						/>

						{q.files ? (
							<div className="fileupload text-center mt-3">
								<h6 className="pb-0 text-muted small mb-2">
									<i className="flaticon-381-upload-1 mr-2"></i>
									Supporting Images
								</h6>
								{q.files.map((file, index) => <UploadImage
													file={file}
													required={q.files_required ? q.files_required : false}
													key={"uploadImg" + index}
													index_on={index}
													current={current}
													section={section}
												/>)}
							</div>
						) : null}
						<GetComment
							question={q}
						/>
					</div>
				</div>
			</div>
		);
	};

	const GetAnswers = ({ s, question, number }) => {

		const [answers, setAnswers] = useLocalStorage('answers');
		const [btns, setBtns] = useState(
			Array.from(document.querySelectorAll(".answer-btn"))
		);

		return s ? (
			<div key={"answercp" + number} className="answers w-100 text-center">
				{question.answers.map((ans, i) => {
					let c = "group d-block m-0 m-auto mb-3 w-100";
					let w = "w-100";
					if (s[number].answers.length > 3) {
						c = (s[number].answers.length > 5 ? " multiple-choice" : "group d-inline-block  mb-3");
						w = "rating";
						if (i + 1 !== s[number].answers.length) {
							c += " ml-2";
						}
					}
					return (
						<div
							id={"abtnc" + number + i}
							key={"abtnc" + number + i}
							className={c}
						>
							<button
								id={"abtn" + number + i}
								onClick={(e) => {

									let ansHook = JSON.parse(localStorage.getItem('answers'));
									btns.forEach((btn) => {
										btn.classList.remove("selected");
									});
									
									ansHook[`section${section}`][number] = {...ansHook[`section${section}`][number], response: ans};
									setAnswers(ansHook);

									e.currentTarget.classList.add("selected");
								}}
								className={
									w +
									" answer-btn d-inline-block btn " +
									(typeof answers[`section${section}`][number]?.response !== "undefined" &&
									answers[`section${section}`][number]?.response == ans
										? "btn-success selected"
										: "btn-outline-dark")
								}
							>
								{ans}
							</button>
						</div>
					);
				})}
			</div>
		) : null;
	};

	const GetComment = ({ question }) => {
		
		const [answers, setAnswers] = useLocalStorage('answers');
		
		return question.comments ? (
			<>
				<textarea
					value={answers[`section${section}`][current]?.comment}
					onChange={(e) => {

						let list = JSON.parse(localStorage.getItem('answers'));
						if(typeof list[`section${section}`][current]==='undefined') {
							
							setAnswers((prevState) => ({...prevState,
								[`section${section}`]: [...prevState[`section${section}`], {...list[`section${section}`][current], comment: e.target.value}] 
							}));
						} else {
							
							list[`section${section}`][current] = {
								...list[`section${section}`][current], 
									comment: e.target.value
							};
							setAnswers((prevState) => ({...prevState,
								[`section${section}`]: list[`section${section}`]}));
						}
					}}
					placeholder={"Comments"}
					className="form-control"
					name={"comment-" + current}
				/>
			</>
		) : null;
	};

	return (
		<>
			<GetQuestions
				questions={questions}
				current={current}
			/>
		</>
	);
};

export default Questions;
