/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../Plugins/Common/Loader";
import NoData from "../Plugins/Common/Tables/NoData";
import 'react-phone-input-2/lib/style.css';
import StaffTable from "../Tables/StaffTable";
import { firestore } from '../../utils/firebase';

const Staff = () => {

    const [office, setOffice] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [isSavingOffice, setIsSavingOffice] = useState(false);
    const [staff, setStaff] = useState([]);
    const [isEdit, setIsEdit] = useState(0);
    const [selected, setSelected] = useState('');
    const [staffOffice, setStaffOffice] = useState('');
    const [isEditStaff, setIsEditStaff] = useState(0);
    const [selectedStaff, isSetSelectedStaff] = useState('');
    const [staffCode, setStaffCode] = useState('');
    const [existingRoles, setExistingRoles] = useState([]);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState("enabled");


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let nowDate = new Date();


    nowDate = nowDate.toISOString().substring(0, 10);
    let tempOffices = [];
    let tempStaff = [];
    let tempRolePosition = [];

    useEffect(() => {
        getOffice();
        getStaff();
        getRolesPosition();
    }, []);

    const startEditStaff = (int, object) => {
        handleShow();
        setIsEditStaff(int);
        setFirstName(object.first_name);
        setLastName(object.last_name);
        setEmail(object.email);
        setPosition(object.position);
        setStaffOffice(object.office);
        setStaffCode(object.short_code);
        setStatus(object.status);
        isSetSelectedStaff(object);
    }
    const cancelEditStaff = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPosition('');
        setStaffOffice('');
        isSetSelectedStaff('');
        setStaffCode('');
        setStatus('');
        setIsEditStaff(0);
    }

    const getRolesPosition = async () => {

        const rolesData = await firestore.collection("roles").where("name", "!=", "").get();
        const filteredrolesData = rolesData._delegate._snapshot.docChanges;
        for (let index = 0; index < filteredrolesData.length; index++) {
            const element = filteredrolesData[index].doc.data.value.mapValue.fields;
            tempRolePosition[index] = { element };
        }

        setExistingRoles(tempRolePosition);
        setIsSavingOffice(false);
    }

    const getOffice = async () => {
        tempOffices = [];
        const email = localStorage.getItem('user_email');
        const officeData = await firestore.collection("office").where('email', '!=', "email").get();
        const filteredOfficedata = officeData._delegate._snapshot.docChanges;
        for (let index = 0; index < filteredOfficedata.length; index++) {
            const element = filteredOfficedata[index].doc.data.value.mapValue.fields;
            tempOffices[index] = { element };
        }

        setOffice(tempOffices);
        setIsSavingOffice(false);
        setIsEdit(0);
        setSelected("");
    }

    const getStaff = async () => {
        tempStaff = [];
        const email = localStorage.getItem('user_email');
        const staffData = await firestore.collection("users").get();
        await staffData.docs.map(item => {
          let row = item.data();
          row.id = item.id;
          tempStaff.push(row);
        })

        setStaff(tempStaff);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPosition('');
        setStaffOffice('');
        isSetSelectedStaff('');
        setStaffCode('');
        setStatus('');
        setIsEditStaff(0);
        setIsSavingOffice(false);

    }

    const createStaff = async () => {
        setIsSavingOffice(true);
        if (firstName == "" || lastName == "" || email == "" || position == "" || staffOffice == "") {
            alert("Please complete all fields!");
            setIsSavingOffice(false);
            return;
        }

        const staffDataWithMail = await firestore.collection("users").where('email', '==', email).get();
        if (staffDataWithMail._delegate._snapshot.docChanges.length > 0) {
            alert("Email must be uniques!");
            setIsSavingOffice(false);
            return;
        }

        const date = new Date();
        const date1 = `${date.getFullYear()}/${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? 0 : ""}${date.getDate()}`;
        const staff = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "position": position,
            "office": staffOffice,
            "created_at": date1,
            "updated_at": date1,
            "user_email": localStorage.getItem("user_email"),
            "short_code": staffCode,
            "status": "enabled"
        }

        await firestore.collection("users").doc().set(staff);
        getStaff();
        handleClose();
    }

    const EditStaff = async () => {
        setIsSavingOffice(true);
        if (firstName == "" || lastName == "" || email == "" || position == "" || staffOffice == "") {
            alert("Please complete all fields!");
            setIsSavingOffice(false);
            return;
        }

        const staffDataWithMail = await firestore.collection("users").where('email', '==', email).get();
        if (staffDataWithMail._delegate._snapshot.docChanges.length > 0) {
            const staffDataWithMailFromDb = staffDataWithMail._delegate._snapshot.docChanges[0].doc.data.value.mapValue.fields.email.stringValue;
            if (staffDataWithMailFromDb != selectedStaff.email) {
                alert("Email must be uniques!");
                setIsSavingOffice(false);
                return;
            }
        }

        const date = new Date();
        const date1 = `${date.getFullYear()}/${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? 0 : ""}${date.getDate()}`;

        await firestore.collection("users").doc(selectedStaff.id).set({
            office: staffOffice,
            email: email,
            first_name: firstName,
            last_name: lastName,
            position: position,
            short_code: staffCode,
            status: status,
            updated_at: date1
        });

        getStaff();
        handleClose();
    }

    const DeleteStaff = async () => {
        setIsSavingOffice(true);
        await firestore.collection("users").where('email', '==', selectedStaff.email).get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    firestore.collection("users").doc(doc.id).delete();
                });
            });
        getStaff();
        setShow(false);
        handleClose();
    }

    return (
      <>
        <div className="card member-list">
          <div className={"card-header"}>
            <h4 className="card-title">User</h4>
            <button
              onClick={() => {
                cancelEditStaff();
                setShow(true);
              }}
              className="btn btn-sm btn-primary btn-rounded"
            >
              Add User
            </button>
          </div>

          <div className="card-body">
            <NoData
              title={false}
              content={
                <div className="w-100 text-center text-muted">
                  <div className="operator-details survey-section  text-center">
                    <div className="form-group mb-1 mb-sm-5">
                      <StaffTable data={staff} startEdit={startEditStaff} />
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>

        <Modal show={show} size="lg" onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3 className="">
                {isEditStaff == 0 && "Add User"}
                {isEditStaff == 1 && "Update User"}
                {isEditStaff == 2 && "Delete User"}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isEditStaff == 2 ? (
              <>
                {" "}
                <h4>Are you sure you want to delete this user ? </h4>
              </>
            ) : (
              <>
                <div className="form-group mb-1 mb-sm-5 row">
                  <div className="col-12 col-sm-6 mb-3">
                    <label>Office Name</label>

                    {office.length > 0 ? (
                      <select
                        className="form-control"
                        onChange={(event) => setStaffOffice(event.target.value)}
                        defaultValue={staffOffice}
                      >
                        <option>
                          Select Office
                        </option>
                        {office.map((data, index) => {
                          return (
                            <option
                              key={index}
                              value={data.element.name.stringValue}
                            >
                              {data.element.name.stringValue}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <input
                        name="office_name"
                        type="text"
                        className="form-control"
                        value="Please create office first"
                        disabled
                      />
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mb-3">
                    <label>Short Code</label>
                    <input
                      type="text"
                      name="staff_code"
                      className="form-control"
                      value={staffCode}
                      onChange={(event) => setStaffCode(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mb-1 mb-sm-5 row">
                  <div className="col-12 col-sm-6 mb-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mb-1 mb-sm-5 row">
                  <div className="col-12 col-sm-6 mb-3">
                    <label>Email Address</label>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>

                  <div className="col-12 col-sm-6 mb-3">
                    <label>Position</label>

                    {existingRoles.length > 0 ? (
                      <select
                        className="form-control"
                        onChange={(event) => setPosition(event.target.value)}
                        defaultValue={position}
                      >
                        <option>
                          Select Position
                        </option>
                        {existingRoles.map((data, index) => {
                          return (
                            <option
                              key={index}
                              value={data.element.name.stringValue}
                            >
                              {data.element.name.stringValue}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <input
                        name="office_name"
                        type="text"
                        className="form-control"
                        value="Please create role&position first"
                        disabled
                      />
                    )}
                  </div>
                </div>
                <div className="form-group mb-1 mb-sm-5 row">
                  <div className="col-12 col-sm-6 mb-3">
                    <label>Status</label>

                    <select
                      className="form-control"
                      id="sel1"
                      name="status"
                      defaultValue={status}
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <option value="enabled">Enabled</option>
                      <option value="disabled">Disabled</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {isEditStaff != 0 && (
              <>
                {isSavingOffice ? (
                  <Loader isLoading={true} />
                ) : (
                  <Button
                    variant="primary"
                    onClick={isEditStaff == 1 ? EditStaff : DeleteStaff}
                  >
                    {isEditStaff == 1 && "Update User"}
                    {isEditStaff == 2 && "Delete User"}
                  </Button>
                )}
              </>
            )}
            {isEditStaff == 0 && (
              <div>
                {isSavingOffice ? (
                  <Loader isLoading={true} />
                ) : (
                  <div>
                    {office.length * existingRoles.length > 0 ? (
                      <Button
                        variant="primary"
                        onClick={(event) => {
                          createStaff();
                        }}
                      >
                        Create User
                      </Button>
                    ) : (
                      <Button variant="primary" disabled>
                        Create User
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
};

export default Staff;
