import React, { Fragment } from "react";
import {
   Row,
   Col,
   Card,
   Table,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";


const BootstrapTable = (props) => {

   return (
     <Fragment>
       <Table responsive={true} className="text-left">
         <thead>
           <tr>
             <th className="width80 hide-mobile text-left">
               <strong>#</strong>
             </th>
             <th>
               <strong>Full Name</strong>
             </th>
             <th className="hide-mobile">
               <strong>Email</strong>
             </th>
             <th className="hide-mobile">
               <strong>Position</strong>
             </th>
             <th className="hide-mobile">
               <strong>Status</strong>
             </th>
             <th></th>
           </tr>
         </thead>
         <tbody>
           {props.data.map((item, index) => (
             <tr key={index}>
               <td className="hide-mobile">
                 <strong>{index + 1}</strong>
               </td>
               <td>
                 {item.first_name}{" "}
                 {item.last_name}
               </td>
               <td className="hide-mobile">{item.email}</td>
               <td className="hide-mobile">
                 {item.position}
               </td>
               <td>
                 <span className={item.status.toLowerCase() == "enabled" ? "green" : "gray"}>●</span>
               </td>
               <td className="text-center">
                 <Dropdown>
                   <Dropdown.Toggle
                     variant="primary"
                     className="sharp outline-dark dark icon-false"
                   >
                     <i className="flaticon-381-edit"></i>
                   </Dropdown.Toggle>
                   <Dropdown.Menu>
                     <Dropdown.Item
                       onClick={() => props.startEdit(1, item)}
                     >
                       <button className="btn m-0 p-0">Edit</button>
                     </Dropdown.Item>
                     <Dropdown.Item
                       onClick={() => props.startEdit(2, item)}
                     >
                       <button className="btn m-0 p-0">Delete</button>
                     </Dropdown.Item>
                   </Dropdown.Menu>
                 </Dropdown>
               </td>
             </tr>
           ))}
         </tbody>
       </Table>
     </Fragment>
   );
};

export default BootstrapTable;
