import React, { useState, useRef, useEffect } from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { options } from "../../../constants/data/defaults";
import { compareAsc, format } from "date-fns";
import { firestore } from "../../../utils/firebase";

const Form = ({ current, canStart, data, handleChange, startQuiz }) => {
  const [offices, setOffices] = useState([]);
  const [office, setOffice] = useState(false);
  const [operator, setOperator] = useState([]);
  const [inspector, setInspector] = useState([]);

  const getOffices = async () => {
    let tempOffices = [];
    const officeData = await firestore.collection("office").get();
    const docs = officeData.docs;

    docs.forEach(function (res) {
      var u = res.data();
      tempOffices.push(u);
    });

    setOffices(tempOffices);
  };

  const getStaff = async () => {
    let operators = [];
    let inspectors = [];
    const staffData = await firestore.collection("users").get();
    const docs = staffData.docs;

    docs.forEach(function (res) {
      var u = res.data();
      u.id = res.id;
      
      if(u.position.toLowerCase().includes('operator')) {
        operators.push(u);
      }
      if(u.position.toLowerCase().includes('inspector')) {
        inspectors.push(u);
      }
    });
    setOperator(operators);
    setInspector(inspectors);
  };

  useEffect(() => {
    getOffices();
    getStaff();
  }, []);
  const getOfficeStaff = async () => {};
  function getFormattedDate(date) {
    return format(date, "P");
  }

  const today = new Date();
  const todayFormatted = getFormattedDate(today);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const dateRef = useRef();

  return (
    <div className="operator-details survey-section  text-center">
      <div key={"field1"} className="form-group mb-1 mb-sm-5">
        <label>Project Name</label>
        <input
          name="project_name"
          type="text"
          className="form-control"
          defaultValue={data.project_name}
          onChange={handleChange}
        />
      </div>
      <div key={"field2"} className="form-group mb-1 mb-sm-5 row">
        <div className="col-6">
          <label>SCC Office</label>
          <select
            value={data.scc_office ?? 0}
            onChange={handleChange}
            className="form-control"
            name="scc_office"
          >
            {offices.map((office) => {
              return (
                <option key={office.name} value={office.name} id={office.id}>
                  {office.name}
                </option>
              );
            })}
            <option key={"none"} value={0}>
              -
            </option>
          </select>
        </div>
        <div className="col-6">
          <label>Date</label>
          <LocalizationProvider
            disableEnforceFocus
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              disableEnforceFocus
              reduceAnimations={true}
              disableCloseOnSelect={true}
              disableOpenPicker={true}
              open={datePickerOpen}
              renderInput={(props) => (
                <input
                  type="text"
                  name="date"
                  ref={props.inputRef}
                  value={format(new Date(data.date ?? today), "P")}
                  className="form-control"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!datePickerOpen) {
                      setDatePickerOpen(true);
                    }
                    return false;
                  }}
                  onChange={(newValue) => {
                    if (datePickerOpen) {
                      handleChange({
                        target: { name: "date", value: newValue },
                      });
                      return false;
                    }
                  }}
                />
              )}
              inputProps={{ inputRef: dateRef }}
              minDate={today}
              onClose={() => {
                setDatePickerOpen(false);
              }}
              onChange={(newValue) => {
                handleChange({ target: { name: "date", value: newValue } });
                setDatePickerOpen(false);
                return false;
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div key={"field3"} className="form-group mb-1 mb-sm-5 row">
        <div className="col-12  col-sm-6">
          <label>Contractor</label>{" "}
          <input
            type="text"
            name="contractor"
            className="form-control"
            value={data.contractor ?? ""}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <div className="col-12  col-sm-6">
          <label>Contact Person</label>{" "}
          <input
            type="text"
            name="contact"
            className="form-control"
            value={data.contact ?? ""}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>{" "}
      </div>{" "}
      <div key={"field4"} className="form-group mb-1 mb-sm-5 row">
        <div className="col-12  col-sm-6">
          <label>Inspector</label>
          <select
            value={data.inspector ?? 0}
            onChange={handleChange}
            className="form-control"
            name="Inspector"
          >
            {/* {options.inspectors.map((el) => (
              <option
                key={el.id}
                value={el.id + " - " + el.name.split(",").reverse().join(" ")}
              >
                {el.id + " - " + el.name.split(",").reverse().join(" ")}
              </option>
            ))} */}

            {inspector.map((s) => {
              let short_code = s.short_code=='' ? 'N/A' : s.short_code;
              return data.scc_office==s.office ? (
                <option key={s.id}  value={short_code + " - " + s.first_name + ' ' + s.last_name}>
                  {short_code} - {s.first_name} {s.last_name}
                </option>
              ) : null;
            })}
            <option value={0}>-</option>
          </select>
        </div>
        <div className="col-12  col-sm-6">
          <label>Operator Inspected</label>
          <select
            value={data.operator ?? 0}
            onChange={handleChange}
            className="form-control"
            name="operator"
          >
            
            {operator.map((s) => {
              let short_code = s.short_code=='' ? 'N/A' : s.short_code;
              return data.scc_office==s.office ? (
                <option key={s.id}  value={short_code + " - " + s.first_name + ' ' + s.last_name}>
                  {short_code} - {s.first_name} {s.last_name}
                </option>
              ) : null;
            })}
            {/* {options.operators.map((el) => (
              <option
                key={el.id}
                value={el.id + " - " + el.name.split(",").reverse().join(" ")}
              >
                {el.id + " - " + el.name.split(",").reverse().join(" ")}
              </option>
            ))} */}
            <option value={0}>-</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Form;
