/* eslint-disable */
import React, { Component, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import MetisMenu from "metismenujs";
import $ from "jquery";
import { UserContext } from "../../../utils/providers/UserProvider";
const path = window.location.pathname;


class MM extends Component {

	componentDidMount() {
		this.$el = this.el;
		this.mm = new MetisMenu(this.$el);
	}
	componentWillUnmount() {
		if (typeof this.mm == 'function') {
			this.mm("dispose");
		}
	}
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const AdminMenu = ({ menu }) => {

	const { user, sidebarDispatch } = useContext(UserContext);

	const onClick = () => {
		const path_ = window.location.pathname;
		sidebarDispatch(path_);
	};

	return <></>
}
/*
Member: Notepad, Settings for User
*/
const UserMenu = ({ menu, setMenuState }) => {
	const { user, sidebarDispatch } = useContext(UserContext);

	const onClick = () => {
		const path_ = window.location.pathname;
		sidebarDispatch(path_);
	};
	return <>

		<li  className={`${path.includes('quality-control') || path == "/" ? "mm-active" : ""}`} onClick={onClick}>
			<Link className={`has-arrow ai-icon`} to="#" aria-expanded="false">
				<i className="flaticon-381-notepad"></i>
				<span className="nav-text">Quality Control</span>
			</Link>
			<ul aria-expanded="true">
				<li>
					<Link to="/quality-control" >Entries</Link>
				</li>
			</ul>
		</li>
	</>

}


const Settings = ({ menu }) => {
	const { user, sidebarDispatch } = useContext(UserContext);

	const onClick = () => {
		const path_ = window.location.pathname;
		sidebarDispatch(path_);
	};

	return (
		<li className={`${path.includes('user-setting') || path.includes('role-position') || path.includes('offices') ? "mm-active" : ""}`} onClick={onClick}>

			<>
				<Link className="has-arrow ai-icon" to="#" aria-expanded="false">
					<i className="flaticon-381-settings"></i>
					<span className="nav-text">Settings</span>
				</Link>
				<ul aria-expanded="true" >
					<li>
						<Link to="/user-setting" >
							User
						</Link>
					</li>
					<li>
						<Link to="/role-position" >
							Roles & Positions
						</Link>
					</li>
					<li>
						<Link to="/offices" >
							Offices
						</Link>
					</li>
					{/* <li>
              <Link to="">Manage Office</Link>
            </li>
            <li>
              <Link to="">Manage Staff</Link>
            </li> */}
				</ul>
			</>

		</li>
	);

}
class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    var menuLinks = $(".mm-show li a");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    $(".deznav").on("click", (event) => {
      if (
        event.target.hasAttribute("href") &&
        !event.target.hasAttribute("class")
      ) {
        btn.click();
      }
    });

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    // const { setMenuState } = this.props;
    // console.log("propsFunction", setMenuState);
    /// Path

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <AdminMenu menu={["", "quality-control"]} />
            <UserMenu menu={[""]} />
            <Settings menu={[""]} />
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
