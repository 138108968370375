/* eslint-disable */
import React, { Fragment, useState, useContext } from "react";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";
import { UserContext } from "../../../utils/providers/UserProvider";

const DhcCreateNav = ({ title, isAuth, setMenuState }) => {
   const [toggle, setToggle] = useState("");
   const { user } = useContext(UserContext);
   const onClick = (name) => setToggle(toggle === name ? "" : name);
   
  const sidebarOnClick = () =>  {
    console.log('work')
  }

   return user?.role && user?.role != "public" ? (
     <Fragment>
       <NavHeader />
       <SideBar setMenuState={setMenuState}  sidebarOnClick={sidebarOnClick} />
       <Header
         onNotification={() => onClick("notification")}
         onProfile={() => onClick("profile")}
         toggle={toggle}
         title={title}
         isAuth={isAuth}
       />
     </Fragment>
   ) : null;
};

export default DhcCreateNav;
