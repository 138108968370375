/* eslint-disable */
import React, { useState, useEffect } from "react";
import Loader from "../Plugins/Common/Loader";
import NoData from "../Plugins/Common/Tables/NoData";
import QualityControlModal from "./QualityControlModal";
import 'react-phone-input-2/lib/style.css'
import { Button } from "react-bootstrap";
import { firestore } from '../../utils/firebase';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './QualityControlList.css';
import QAQCPDF from "./components/QAQCPDF";
import useLocalStorageState from 'use-local-storage-state';
import spacetime from 'spacetime';
const QualityControlList = ({ activeQualityControl, modalOpen, toggleActiveQualityControl,
	toggleModal, isLoading, dataFetched }) => {

	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	const [showGenerateButton, setShowGenerateButton] = useState(false);
	const [isGettingData, setIsGettingData] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const pdfExportComponent = React.useRef(null);
	const [totalNumber, setTotalNumber] = useState(0);
	const [submissionData, setSubmissionData] = useState([]);
	const [showPreview, setShowPreview] = useState(false);
	const [yearArray, setYearArray] = useState([]);
	const [monthArray, setMonthArray] = useState([]);
	const [officeToReport, setOfficeToReport] = useState('');
	const [startReportOffice, setStartReportOffice] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState("");
	const [selectedYear, setSelectedYear] = useState(0);
	const [reportOperator, setReportOperator] = useState([]);
	const [reportInspector, setReportInspector] = useState([]);
	const [reportTableData, setReportTableData] = useState([]);
	const [offices, setOffices] = useState([]);
	const [qaqcData, setQaqcData] = useState([]);

	let tempOffices = [];

  const [formData, setFormData, {removeItem}] = useLocalStorageState(
    "userData",
    { defaultValue: {
      project_name: "",
      date: new Date(),
    }}
  );
	const generatePreview = (index) => {
		setSelectedData(submissionData[index]);
		setShowPreview(true);
	}

	const cancelDownload = () => {
		setShowPreview(false);
	}

	const downloadPDF = () => {
		pdfExportComponent.current.save();
		setShowPreview(false);
	}

	const getSubmittedData = async () => {
		setSubmissionData([]);
		setTotalNumber(0);
    const qaqc_en = await firestore.collection("qaqc_entries").get();
		if (qaqc_en.docs.length > 0) {
      qaqc_en.docs.forEach(element => {
        let rec = element.data();
				setTotalNumber((prevState) => prevState + 1);
				setSubmissionData((prevState) => [...prevState, JSON.parse(rec.data)]);
			});
		}
	}

	const setQCOffice = async (officeToReport) => {
		setOfficeToReport(officeToReport);
		setSelectedYear(0);
		setSelectedMonth("");
    const qaqc_ab = await firestore.collection("qaqc_abstract").where('office', '==', officeToReport).get();
    const ysArr = [];
		let dataArr = await qaqc_ab.docs.map(doc => {
			let res = doc.data();
			let year = res.date.toDate().getFullYear();
			if (!(ysArr.indexOf(year) > -1)) {
				ysArr.push(year)
			}
			return res;
		});
		
		setQaqcData(dataArr);
		setYearArray(ysArr);
		setMonthArray([]);
	};

	const handleYearChange = (selectedYear) => {
		setSelectedYear(selectedYear);
		const msArr = [];
		qaqcData.map(doc => {
			let year = doc.date.toDate().getFullYear();       
			let month = doc.date.toDate().getMonth();
			if (selectedYear == year && !(msArr.indexOf(months[month]) > -1)) {
				msArr.push(months[month])
			}
		});
		setMonthArray(msArr)
	}

	useEffect(async () => {
		getSubmittedData();
		tempOffices = [];
    
		const officeData = await firestore.collection("office").get();
    const docs = officeData.docs;

    docs.forEach(function (res) {
      var u = res.data();
      tempOffices.push(u);
    });

    setOffices(tempOffices);
	}, []);

	useEffect(() => {
		if (selectedYear !== 0 && selectedMonth !== "") {
			setShowGenerateButton(true);
			getReportData();
		}
		else {
			setShowGenerateButton(false);
		}
	}, [selectedYear, selectedMonth])

	
	const getPointsSectionOne = () => {
		if (!selectedData) {
			return 0;
		}
    let temp =0;
    for (let index = 0; index < selectedData.answers.section2.length; index++) {
      if(selectedData.answers.section2[index]?.response == "yes")
      {
        temp+=5;
      }
    }
    for (let index = 0; index < selectedData.answers.section2.length; index++) {
      if(selectedData.answers.section2[index]?.response){
        if(typeof(selectedData.answers.section2[index]?.response)==="number"){
          temp = temp + selectedData.answers.section2[index]?.response;
        }
      }
    }
		return temp;
	}

	const getPointsSectionTwo = () => {
		if (!selectedData) {
			return 0;
		}
		let temp = 0;
    if (
      selectedData.answers.section3[5]?.response == "yes" ||
      selectedData.answers.section3[5]?.response == "n/a"
    ) {
      temp += 5;
    }
    if (
      selectedData.answers.section3[6]?.response == "yes" ||
      selectedData.answers.section3[6]?.response == "n/a"
    ) {
      temp += 5;
    }
    if (
      selectedData.answers.section3[7]?.response == "yes" ||
      selectedData.answers.section3[6]?.response == "n/a"
    ) {
      temp += 5;
    }
    if(selectedData.answers.section3[13]?.response == "yes"){
      temp+=5;
    }

    for (let index = 0; index < selectedData.answers.section3.length; index++) {
      if(selectedData.answers.section3[index]?.response&&typeof(selectedData.answers.section3[index]?.response)==="number"){
        temp = temp + selectedData.answers.section3[index]?.response
      }

    }
		return temp;
	}

	const pointsSectionOne = getPointsSectionOne();
	const pointsSectionTwo = getPointsSectionTwo();

	const getReportData = async () => {
		setIsGettingData(true);
		let date = Date.parse(`01 ${selectedMonth.substring(0, 3)} ${selectedYear} 00:00:00 GMT`);
		let firstdayOfMonth = new Date(date);
		const qaqc_ab = await firestore.collection("qaqc_abstract").where('office', '==', officeToReport).get();
		let dataArray = await qaqc_ab.docs.map(doc => doc.data());

		dataArray = dataArray.filter(item => item.date.toDate() >= firstdayOfMonth &&
			item.date.toDate() < new Date(selectedYear, item.date.toDate().getMonth() + 1, 0))

		let tempOperator = [];
		let tempInspector = [];
		await dataArray.map(doc => {
			if (!tempInspector.includes(doc.inspector)) {
				tempInspector.push(doc.inspector);
				setReportInspector(tempInspector);
			}
		});

		let seven_days = new Date(firstdayOfMonth);
		let fourtin_days = new Date(firstdayOfMonth);
		let twentyone_days = new Date(firstdayOfMonth);
		seven_days.setDate(seven_days.getDate() + 6);
		fourtin_days.setDate(fourtin_days.getDate() + 13);
		twentyone_days.setDate(twentyone_days.getDate() + 20);
		let tempReportTableData = [];
		for (let index = 0; index < tempOperator.length; index++) {
			tempReportTableData[index] = [];
			let tempRow = [[[], 0], [[], 0], [[], 0], [[], 0]];
			dataArray.forEach(doc => {
				if (doc.operator == tempOperator[index]) {
					if (doc.date.toDate() >= firstdayOfMonth && doc.date.toDate() < seven_days) {
						tempRow[0][0].push(doc.inspector.split('-')[1])
						tempRow[0] = [tempRow[0][0], tempRow[0][1] + parseInt(doc.points)]
					}
					if (doc.date.toDate() >= twentyone_days && doc.date.toDate() <= new Date(selectedYear, doc.date.toDate().getMonth() + 1, 0)) {
						tempRow[3][0].push(doc.inspector.split('-')[1])
						tempRow[3] = [tempRow[3][0], tempRow[3][1] + parseInt(doc.points)]
					}
				}
			});
			tempReportTableData[index] = tempRow
		}
		setReportTableData(tempReportTableData);
		setIsGettingData(false);
	}

	const confirmExportData = () => {
		setStartReportOffice(false);
		setReportInspector([]);
		setReportOperator([]);
		setReportTableData([]);
		setSelectedMonth("");
		setSelectedYear(0);
	}

	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}


	return (
    <>
      <QualityControlModal
        getSubmittedData={getSubmittedData}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        qualityControl={activeQualityControl}
      />
      {startReportOffice && (
        <div className="modal-for-report-office">
          <div className="office-report-modal-content">
            <div className="mb-5">
              {" "}
              <h2>Master Logs</h2>
            </div>
            <div className="form-group row m-0">
              <div className="col-12  col-sm-12 mb-2">
                <table id="emp" className="table" style={{ display: "none" }}>
                  {" "}
                  <thead style={{ background: "lightblue" }}>
                    <tr>
                      <th colSpan={10}>
                        Safety and Quality control Inspections Log -{" "}
                        {officeToReport}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={10}>
                        Master Log {selectedMonth} {selectedYear}
                      </th>
                    </tr>
                    <tr>
                      <th rowSpan={2}>Operator Number</th>
                      <th rowSpan={2}>Operator Name</th>
                      <th colSpan={2}>Week1</th>
                      <th colSpan={2}>Week2</th>
                      <th colSpan={2}>Week3</th>
                      <th colSpan={2}>Week4</th>
                    </tr>
                    <tr>
                      <th>QAQC</th>
                      <th>Score</th>
                      <th>QAQC</th>
                      <th>Score</th>
                      <th>QAQC</th>
                      <th>Score</th>
                      <th>QAQC</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportOperator.length > 0 &&
                      reportOperator.map((item, i) => {
                        return (
                          <tr key={`5rKNxC4CfNAx8G` + i}>
                            <td>{item.split("-")[0]}</td>
                            <td>{item.split("-")[1]}</td>
                            {reportTableData.length > 0 &&
                              reportTableData[i].map((item, i1) => {
                                return (
                                  <>
                                    <td key={i1 + "KNxC4CfNAx8GudPJrK"}>
                                      {item[0].filter(onlyUnique).join(", ")}
                                    </td>
                                    <td key={i1 + "KNxC4CfNAx8GudPJrK" + 1}>
                                      {item[1]}
                                    </td>
                                  </>
                                );
                              })}
                          </tr>
                        );
                      })}
                  </tbody>
                  {/* <table>
                    <thead>
                      <tr>
                        <th>Inspector Names and Numbers</th>
                      </tr>
                      <tr>
                        <th>Inspector Number</th>
                        <th>Inspector Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportInspector.map((item, i) => {
                        return (
                          <tr>
                            <td key={i + "$.qiXBxH5rKNxC4CfNA"}>
                              {item.split("-")[0]}
                            </td>
                            <td key={i + "$.qiXBxH5rKNxC4CfNA" + 1}>
                              {item.split("-")[1]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> */}
                </table>
                <div className="form-group mb-1 mb-sm-3 row">
                  <div className="col-12  col-sm-12 mb-3">
                    <select
                      className="form-control"
                      onChange={(event) => {
                        setQCOffice(event.target.value);
                      }}
                    >
                      <option disabled selected>
                        Select Office
                      </option>
                      {offices?.map((item, key) => {
                        return (
                          <option key={key} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12  col-sm-6 mb-3">
                    <select
                      className="form-control"
                      onChange={(event) => handleYearChange(event.target.value)}
                      value={selectedYear}
                    >
                      <option value={0} selected>
                        Select Year
                      </option>
                      {yearArray?.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12  col-sm-6 mb-3">
                    <select
                      className="form-control"
                      onChange={async (event) => {
                        setSelectedMonth(event.target.value);
                      }}
                      value={selectedMonth}
                    >
                      <option value={""} selected>
                        Select Month
                      </option>
                      {monthArray.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {showGenerateButton && !isGettingData ? (
                  <ReactHTMLTableToExcel
                    table="emp"
                    filename={
                      "QAQC_LOG_" +
                      officeToReport.replace(" ", "_").toUpperCase() +
                      "_" +
                      selectedYear +
                      "_" +
                      selectedMonth
                    }
                    buttonText="Generate Master Log"
                    onClick={confirmExportData}
                    className={`btn btn-primary w-100 ${
                      !showGenerateButton || isGettingData ? "disabled" : ""
                    }`}
                  />
                ) : (
                  <Button
                    disabled
                    variant="light"
                    className="w-100 mt-3 text-dark"
                  >
                    Generate Master Log
                  </Button>
                )}
              </div>
              <div className="col-12  col-sm-12 mt-3">
                <Button
                  className="w-100 link btn-link text-dark pb-0"
                  onClick={() => setStartReportOffice(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card member-list hide-while-loading">
        <div className={"card-header" + (!dataFetched ? " invisible" : "")}>
          <h4 className="card-title">Entries</h4>{" "}
          <div>
            <button
              onClick={() => {
                removeItem();
                toggleModal(true);
              }}
              className="btn btn-sm btn-primary btn-rounded"
            >
              New QAQC
            </button>
            <button
              onClick={() => {
                setStartReportOffice(true);
              }}
              className="btn ml-2 btn-sm btn-primary btn-rounded btn-outline-dark"
            >
              View Logs
            </button>
          </div>
        </div>

        <div className="card-body">
          {isLoading || !dataFetched ? (
            <Loader isLoading={true} />
          ) : submissionData.length > 0 ? (
            <>
              {!showPreview && (
                <div className="table-responsive">
                  <div
                    id="QualityControlListwrapper"
                    className="dataTables_wrapper "
                  >
                    <table
                      className="table display mb-4 dataTablesCard card-table dataTable text-left"
                      id="QualityControlList"
                      role="grid"
                      aria-describedby="QualityControlListinfo"
                    >
                      <thead>
                        <tr role="row" className="text-left">
                          <th
                            className="text-muted sorting hide-mobile"
                            tabIndex={0}
                            aria-controls="QualityControlList"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="ID: activate to sort column ascending"
                          >
                            Office
                          </th>

                          <th
                            className="text-muted sorting"
                            tabIndex={1}
                            aria-controls="QualityControlList"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="ID: activate to sort column ascending"
                          >
                            Operator
                          </th>
                          <th
                            className="text-muted sorting"
                            tabIndex={2}
                            aria-controls="QualityControlList"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="ID: activate to sort column ascending"
                          >
                            Inspector
                          </th>
                          <th
                            className="text-muted sorting hide-mobile"
                            tabIndex={3}
                            aria-controls="QualityControlList"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="ID: activate to sort column ascending"
                          >
                            Project
                          </th>
                          <th
                            className="text-muted sorting"
                            tabIndex={4}
                            aria-controls="QualityControlList"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="ID: activate to sort column ascending"
                          >
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-top-0">
                        {submissionData.map((data, index) => {
                          return (
                            <tr
                              onClick={() => {
                                generatePreview(index);
                              }}
                              key={index + `KNxC4CfNAx8GudPJ`}
                              role="row"
                            >
                              <td className="pointer hide-mobile">
                                <div>{data.scc_office}</div>
                              </td>

                              <td className="pointer">
                                <div>{data.operator.split("-")[1].trim()}</div>
                              </td>
                              <td className="pointer">
                                <div>{data.inspector.split("-")[1].trim()}</div>
                              </td>
                              <td className="pointer hide-mobile">
                                <div className="py-2 d-inline-block fs-16 mb-0 text-capitalize text-truncate">
                                  {data.project_name.toLowerCase()}
                                  <span className="mall d-block text-muted text-truncate hide-mobile">
                                    {data.contractor}
                                  </span>{" "}
                                </div>
                              </td>
                              <td className="pointer">
                                <div className="media">
                                  {spacetime(data.date).format("numeric-us")}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex align-items-end justify-content-end pr-4">
                      <div className="dataTables_info text-muted m-0 p-0 text-right">
                        <small> {totalNumber} Total</small>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showPreview && (
                <div>
                  <>
                    <QAQCPDF
                      selectedData={selectedData}
                      pdfExportComponent={pdfExportComponent}
                      pointsSectionOne={pointsSectionOne}
                      pointsSectionTwo={pointsSectionTwo}
                    />
                  </>
                  <div className="row">
                    <div className=" col-12 col-sm-6 mt-3">
                      <Button
                        className="w-100 btn-light btn-muted text-dark border-none outline-none"
                        onClick={cancelDownload}
                      >
                        Back to List
                      </Button>
                    </div>{" "}
                    <div className="col-12 col-sm-6 mt-3">
                      <Button className="w-100" onClick={downloadPDF}>
                        Download PDF
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <NoData
              title={false}
              content={
                <div className="w-100 text-center text-muted">
                  <i className="flaticon-381-success-2 text-success mr-2"></i>
                  {"Up to Date"}
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default QualityControlList;
