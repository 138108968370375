import react, { useEffect, useState } from "react";



const Loader = ({isLoading, style}) => {

  const [show, setShow] = useState(isLoading);

  useEffect(() => {

    return () => {
      const hidden = document.getElementsByClassName("hide-while-loading");

      if (hidden.length > 0) {
       
        for (var i = 0; i < hidden.length; i++) {
          hidden[i].classList.remove("hide-while-loading");
        }
      }
    };
  }, []);

     
    return show ? <div style={style ? style : {}}className="position-absolute h-100 w-100 d-table"><div className="w-100 h-100 text-center d-table-cell align-middle">

    <div className="spinner-grow"  role="status">
  <span className="sr-only">Loading...</span>
</div>  </div></div>: '';
}
export default Loader;