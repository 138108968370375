import React from "react";
import { PDFExport } from '@progress/kendo-react-pdf';
import { questions, logoBase64 } from './data.js'
import spacetime from 'spacetime';
const QAQCPDF = ({pdfExportComponent, pointsSectionOne, pointsSectionTwo, selectedData}) => {
	let nowDate = new Date();
	nowDate = nowDate.toISOString().substring(0, 10);

    return (
      <>
        <PDFExport paperSize={`A4`} margin="2cm" ref={pdfExportComponent}>
          <div className="pdf-export p-3">
            <div className="survey-section logo mb-2 text-center">
              <img src={logoBase64} width="100px" alt="Speedy" />
            </div>
            <div className="survey-section text-center">
              <div className="form-group mt-3 mb-5">
                <p>
                  <b>
                    Speedy Concrete Cutting Quality Assurance, Quality Control
                    and Safety Survey
                  </b>
                </p>
              </div>
              <div className="form-data-section survey-section mb-5 text-justify">
                <div className="form-group mb-1 row">
                  <div className="col-6">
                    <p>
                      <span className="bold data-label">Project</span>
                      <span className="data-value d-block ">
                        {selectedData.project_name}
                      </span>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold data-label">Date</span>
                      <span className="data-value d-block ">
                        {spacetime(
                          selectedData.date,
                          "America/New_York"
                        ).format("numeric-us")}
                      </span>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold data-label"> Office</span>
                      <span className="data-value d-block ">
                        {selectedData.scc_office}
                      </span>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold data-label">Contractor</span>
                      <span className="data-value d-block ">
                        {selectedData.contractor}
                      </span>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold data-label">Contact Person</span>
                      <span className="data-value d-block ">
                        {selectedData.contact}
                      </span>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold data-label">Inspector</span>
                      <span className="data-value d-block ">
                        {selectedData.inspector}
                      </span>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold data-label">
                        Operator Inspected
                      </span>
                      <span className="data-value d-block ">
                        {selectedData.operator}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group text-left mt-3">
                <p>
                  <b>
                    Customer Rating of Safety, Quality, Performance & Service
                  </b>
                </p>
              </div>
              <div className="survey-section  text-left">
                <div className="form-group mb-1 row text-justify">
                  {questions[1].questions.map((question, index) => {
                    return (
                      <div key={"A" + index} className="col-12">
                        <p className="mb-0">
                          <span className="bold data-label">{index + 1}. </span>
                          <span>{question.question}</span>
                        </p>
                        <p
                          className={"mt-3 text-uppercase bold text-right " }
                        >
                          {selectedData.answers.section2[index]
                            ? selectedData.answers.section2[index].response
                            : "No answer provided."}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="form-group text-left">
                <p>
                  <b>
                    Inspector Rating of Safety, Quality of Work Performance &
                    Customer Service Performance
                  </b>
                </p>
              </div>
            </div>
            <div className="survey-section  text-left">
              <div className="form-group mb-1 mb-sm-5 row">
                {selectedData.answers.section3.map((items, index) => {
                  return (
                    <div key={"B" + index} className="col-12 mt-4">
                      <p>
                        <span className="bold data-label">{index + 1}. </span>
                        <span>{questions[2].questions[index].question}</span>
                      </p>
                      {items ? (
                        <>
                          {Object.keys(items).map((key) => {
                            return (
                              <div
                                className={
                                  window.innerWidth > 762
                                    ? `col-12 my-1`
                                    : `my-1`
                                }
                                key={key}
                              >
                                {key == "comment" && (
                                  <div
                                    className={
                                      window.innerWidth > 762
                                        ? `col-12 row`
                                        : `col-12 row pr-0`
                                    }
                                  >
                                    <div
                                      className={
                                        window.innerWidth > 762
                                          ? `col-2 text-right`
                                          : `col-12 row`
                                      }
                                    >
                                      Comment
                                    </div>
                                    <div
                                      className={
                                        window.innerWidth > 762
                                          ? `"col-10 data-value"`
                                          : `col-12 data-value`
                                      }
                                    >
                                      {" "}
                                      {items[key]}
                                    </div>
                                  </div>
                                )}
                                {key == "response" && (
                                  <div className="text-uppercase bold strong col-12 row">
                                    <div className={"col-12"}>
                                      <div className="response-value">
                                        {Number.isInteger(items[key]) ? (
                                          <span className="rating-label d-inline-block mr-3">
                                            Rating:
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {items[key]}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {key == "img" && (
                                  <>
                                    {items[key].map((item, index1) => {
                                      return (
                                        <div
                                          key={index1}
                                          className={
                                            window.innerWidth > 762
                                              ? `col-12 my-3`
                                              : `my-3`
                                          }
                                        >
                                          {
                                            questions[2].questions[index].files[
                                              index1
                                            ]
                                          }
                                          <div
                                            key={index1}
                                            className={
                                              window.innerWidth > 762
                                                ? `col-12 my-1 row`
                                                : `my-1 row`
                                            }
                                          >
                                            {item.map((img, index2) => {
                                              return (
                                                <div className="col-12 col-sm-4">
                                                  <img
                                                    src={img.data_url}
                                                    width="80%"
                                                    className="my-2"
                                                  />
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
                <div className="col-12 my-4 text-center">
                  <div
                    className={
                      window.innerWidth > 762 ? `ml-5 pl-5 mt-2` : `mt-2`
                    }
                  >
                    <div className="points my-3 text-center">
                      <h5>
                        <strong>Total Points Earned </strong>
                        <span className="display-5 data-value">
                          {pointsSectionOne + pointsSectionTwo}
                        </span>{" "}
                      </h5>
                      <span className="note text-gray">
                        of 100 points possible.
                      </span>
                      <div className="small mt-3">
                        <span className="data-value">
                          <em>Section </em>
                          A:
                          {pointsSectionOne} B:
                          {pointsSectionTwo}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  {selectedData.additionalComments ? (
                    <p>
                      {"Additional Comments: "}
                      <span className="data-value d-block "></span>
                    </p>
                  ) : null}
                </div>
                <div className="col-12 mb-3">
                  <p className="text-center mb-0">Acknowledgement</p>
                  <p className="text-center">
                    “I have reviewed and understand the above evaluation of my
                    jobsite performance and compliance with Company policies and
                    procedures. I also understand that non-compliance with
                    policies and procedures will result in corrective training
                    and/or disciplinary actions.”
                  </p>
                </div>
                <div className="col-6 text-center">
                  <img
                    src={selectedData.operatorSignature.data}
                    height="25px"
                    className="data-value"
                  />
                  <hr className="m-0 border"></hr>
                  <div className="signature-label  mb-3">
                    <div>
                      <strong>{selectedData.operator}</strong>
                    </div>
                    Operator/Employee
                  </div>
                </div>
                <div className="col-6 text-center">
                  <p className="m-0">{spacetime().format("numeric-us")}</p>
                  <hr className="m-0 border"></hr>
                  <p>Date</p>
                </div>
                <div className="col-6 text-center">
                  <img
                    src={selectedData.inspectorSignature.data}
                    height="25px"
                  />
                  <hr className="m-0 border"></hr>
                  <div className="signature-label mb-3">
                    <div>
                      <strong>{selectedData.inspector}</strong>
                    </div>
                    SCC Inspector
                  </div>
                </div>
                <div className="col-6 text-center">
                  <p className="m-0">{nowDate}</p>
                  <hr className="m-0 border"></hr>
                  <p>Date</p>
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
      </>
    );
}

export default QAQCPDF;