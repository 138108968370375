import React, { useRef, useEffect, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import {format} from "date-fns";
import useLocalStorage  from "../../../utils/hooks/useLocalStorage";
import './acknowledgment.css';
import './../QualityControlList.css';
import QAQCPDF from './QAQCPDF';
import spacetime from "spacetime";

export function Acknowledgement({ setPoints1, setPoints2, setClearSignature,clearSignature,formData, setAddiData, setReadySubmit, finalData,setPdfData,setIsConfirmSignature }) {
  const operatorSignatureRef = useRef(null);
  const inspectorSignatureRef = useRef(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showPdfFinal, setShowPdfFinal] = useState(false);
  const pdfExportComponent = React.useRef(null);
  const [formD, setFormD] = useLocalStorage('answers');
  
  if(clearSignature==true) {
    inspectorSignatureRef.current.clear();
    operatorSignatureRef.current.clear();
  }
  
  
  let nowDate = new Date();
  nowDate = nowDate.toISOString().substring(0,10);
 
  
	const getPointsSectionOne = () => {
		if (!finalData) {
			return 0;
		}
    let temp =0;
    for (let index = 0; index < finalData.answers.section2.length; index++) {
      if(finalData.answers.section2[index]?.response == "yes")
      {
        temp+=5;
      }
    }
    for (let index = 0; index < finalData.answers.section2.length; index++) {
      if(finalData.answers.section2[index]?.response){
        if(typeof(finalData.answers.section2[index]?.response)==="number"){
          temp = temp + finalData.answers.section2[index]?.response;
        }
      }
    }
    if(temp > 0) {
      setPoints1(temp);
    }
		return temp;
	}

	const getPointsSectionTwo = () => {
		if (!finalData) {
			return 0;
		}
		let temp = 0;
    if (
      finalData.answers.section3[5]?.response == "yes" ||
      finalData.answers.section3[5]?.response == "n/a"
    ) {
      temp += 5;
    }
    if (
      finalData.answers.section3[6]?.response == "yes" ||
      finalData.answers.section3[6]?.response == "n/a"
    ) {
      temp += 5;
    }
    if (
      finalData.answers.section3[7]?.response == "yes" ||
      finalData.answers.section3[6]?.response == "n/a"
    ) {
      temp += 5;
    }
    if(finalData.answers.section3[13]?.response == "yes"){
      temp+=5;
    }

    for (let index = 0; index < finalData.answers.section3.length; index++) {
      if(finalData.answers.section3[index]?.response&&typeof(finalData.answers.section3[index]?.response)==="number"){
        temp = temp + finalData.answers.section3[index]?.response
      }

    }
    if(temp > 0) {
      setPoints2(temp);
    }
		return temp;
	}

  const pointsSectionOne = getPointsSectionOne();
  const pointsSectionTwo = getPointsSectionTwo();




  useEffect(() => {
    // setAddiData(formD);
    let opSig = operatorSignatureRef.current;
    let inSig = inspectorSignatureRef.current;
    
    opSig.signaturePad.onEnd = (e) => {
      setClearSignature(false)
      const o = opSig.toDataURL();
      let f = formD;
      f.operatorSignature = { data: o, timestamp: new Date() };
      setFormD(f);
    }
    inSig.signaturePad.onEnd = (e) => {
            setClearSignature(false);
             const o = inSig.toDataURL();
             let f = formD;
             f.inspectorSignature = { data: o, timestamp: new Date() };
             setFormD(f);
             setAddiData(f);
             setIsConfirmSignature(true);
             setShowPreview(false);
             setShowPdfFinal(true);
             setPdfData(pdfExportComponent);
        };


  }, [])
  const saveSignature = (e) => {
    console.log(e);
  }


  return (
    <div>
      {showPdfFinal && (
        <>
          <div className="m-2 d-none">
            <QAQCPDF
              selectedData={finalData}
              pdfExportComponent={pdfExportComponent}
              pointsSectionOne={pointsSectionOne}
              pointsSectionTwo={pointsSectionTwo}
            />
          </div>
        </>
      )}
      {!showPreview && (
        <div>
          <div className="total-points mb-5">
            Total points scored this inspection <br />
            <strong>
              {pointsSectionOne + pointsSectionTwo} of 100 points possible
            </strong>
          </div>{" "}
          <div>
            <strong className="d-block mb-2">{"Acknowledgement"} </strong>
          </div>
          <small className="d-block mb-4">
            <em>
              {
                '"I have reviewed and understand the above evaluation of my jobsite performance and compliance with Company policies and procedures. I also understand that non-compliance with policies and procedures will result in corrective training and/or disciplinary actions."'
              }
            </em>
          </small>
          <div id="operatorSig" className="signature mt-5">
            <div className="row text-left  mb-2">
              <div className="col">
                <small className="d-block">
                  <strong>Operator Name</strong>
                </small>
                <small className="d-block">
                  {formData.operator
                    ? formData.operator.split(" - ")[1]
                    : "Operator Name"}
                </small>
              </div>{" "}
              <div className="col">
                <small className="d-block">
                  <strong>Date</strong>
                </small>
                <small className="d-block">{spacetime(formData.date ?? null).format("numeric-us")}</small>
              </div>
            </div>

            <div className="pre-sign">
              {" "}
              <SignaturePad
                onEnd={() => console.log(this.signaturePad.toDataURL())}
                ref={operatorSignatureRef}
              />
            </div>
          </div>
          <div id="inspectorSig" className="signature mt-4 mb-5">
            <div className="row text-left  mb-2">
              <div className="col">
                <small className="d-block">
                  <strong>Inspector</strong>
                </small>
                <small className="d-block">
                  {formData.inspector
                    ? formData.inspector.split(" - ")[1]
                    : "Inspector Name"}
                </small>
              </div>{" "}
              <div className="col">
                <small className="d-block">
                  <strong>Date</strong>
                </small>
                <small className="d-block">
                  {formData.date && format(new Date(formData.date ?? ""), "P")}
                </small>
              </div>
            </div>

            <div className="pre-sign">
              {" "}
              <SignaturePad onEnd={saveSignature} ref={inspectorSignatureRef} />
            </div>
          </div>{" "}
        </div>
      )}
      {/* {showConfirmSignButton&&<div className="row">
        <div className="col-12 col-sm-6 mt-3">
          <Button className="w-100 btn-secondary" 
            onClick={() => {
              inspectorSignatureRef.current.clear();
              operatorSignatureRef.current.clear();
              setIsConfirmSignature(false);}
            }
          >
            Clear Signature
          </Button>
        </div>                
        <div className=" col-12 col-sm-6 mt-3">
          <Button 
            className="w-100 btn-secondary" 
            disabled = {isConfirmSignature?false:true} 
            onClick = {confirmSignature}
            >
            Confirm Signature
          </Button>
        </div>
      </div>} */}
    </div>
  );
}
