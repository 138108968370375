import React, { useState, useEffect, useRef, createContext } from "react";
import { auth, UserController } from "../firebase";
import useLocalStorage from "../hooks/useLocalStorage";
export const UserContext = createContext({ user: null });


const UserProvider = props => {
  const [user, setUser] = useState({ initialized: false });
  const [menuState, setMenuState] = useState(null);
  const [sidebarClick, setSidebarClick] = useState(window.location.pathname);

  const sidebarDispatch = (value) => {
    setSidebarClick(value);
  }
  const menuStateDispatch = (value) => {
    setMenuState(value);
  }

  // eslint-disable-next-line
  const [formObj, setFormObj] = useLocalStorage("userData", user);
  const userRef = useRef(user);
  /* eslint-disable */
  const setFormObjRef = useRef(setFormObj);

  useEffect(() => {

    auth.onAuthStateChanged(async (result) => {

      if (result) {
        const { displayName, email, photoURL, createdAt } = result;
        const profile = await UserController.getUser(result.uid); const isAdmin =
          email.includes("@speedycon.com") ||
            email.includes("maiko@maiko.company") ||
            (result.role && result.role.toLowerCase() === "admin")
            ? true
            : false;
           
        let d = { displayName, email, photoURL, createdAt };

        d.role = isAdmin ? "admin" : "user";
        d.isAdmin = isAdmin;

        d.firstName = displayName.split(" ")[0];
        d.lastName = displayName.split(" ")[1];
        userRef.current = {
          ...userRef.current,
          ...d,
        };


        if (profile) {
          userRef.current = { ...userRef.current, ...profile };
        }

      }

      setUser({ ...userRef.current, ...{ initialized: true } });
    });
  }, []);

  useEffect(() => {
    /* eslint-disable */
    setFormObj(user);
  }, [user]);
  return (
    <UserContext.Provider value={{
      user, menuState, menuStateDispatch, sidebarClick, sidebarDispatch
    }}>{props.children}</UserContext.Provider>
  )
}

export default UserProvider;