/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../Plugins/Common/Loader";
import NoData from "../Plugins/Common/Tables/NoData";
import 'react-phone-input-2/lib/style.css';
import PositionTable from "../Tables/PositionTable";
import { firestore } from '../../utils/firebase';

const RoleAndPosition = () => {

	const [isSavingOffice, setIsSavingOffice] = useState(false);
	const [rolePosition, setRolePosition] = useState('');
	const [isEditRolePosition, setIsEditRolePosition] = useState(0);
	const [existingRoles, setExistingRoles] = useState([]);
	const [selectedRole, setSelectedRole] = useState('');
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	let nowDate = new Date();

	nowDate = nowDate.toISOString().substring(0, 10);
	let tempRolePosition = [];

	useEffect(() => {
		getRolesPosition();
	}, []);

	const startEditRoles = (int, object) => {
		handleShow();
		setIsEditRolePosition(int);
		setSelectedRole(object);
		setRolePosition(object.name.stringValue);
	}

	const getRolesPosition = async () => {

		const rolesData = await firestore.collection("roles").where("name", "!=", "").get();
		const filteredrolesData = rolesData._delegate._snapshot.docChanges;
		for (let index = 0; index < filteredrolesData.length; index++) {
			const element = filteredrolesData[index].doc.data.value.mapValue.fields;
			tempRolePosition[index] = { element };
		}

		setExistingRoles(tempRolePosition);
		setIsSavingOffice(false);
		setRolePosition("");
		setIsEditRolePosition(0);
	}

	const createRolesPosition = async () => {
		setIsSavingOffice(true);
		if (rolePosition == "") {
			alert("Please input the position");
			setIsSavingOffice(false);
			return;
		}

		const rolesData = await firestore.collection("roles").where('name', '==', rolePosition).get();
		if (rolesData._delegate._snapshot.docChanges.length > 0) {
			alert("Role&Position must be unique!");
			setIsSavingOffice(false);
			return;
		}

		const date = new Date();
		const date1 = `${date.getFullYear()}/${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? 0 : ""}${date.getDate()}`;
		const newRolesPostions = {
			name: rolePosition,
			updated_at: date1
		};
		firestore.collection('roles').doc().set(newRolesPostions);
		getRolesPosition();
		handleClose();
	}

	const editRolesPosition = async () => {

		setIsSavingOffice(true);
		if (rolePosition == "") {
			alert("Please input the position");
			setIsSavingOffice(false);
			return;
		}

		const roledata = await firestore.collection("roles").where('name', '==', rolePosition).get();
		if (roledata._delegate._snapshot.docChanges.length > 0) {
			const roledata1 = roledata._delegate._snapshot.docChanges[0].doc.data.value.mapValue.fields.name.stringValue;
			if (roledata1 != selectedRole.name.stringValue) {
				alert("Role&Position must be unique!");
				setIsSavingOffice(false);
				return;
			}
		}

		const date = new Date();
		const date1 = `${date.getFullYear()}/${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? 0 : ""}${date.getDate()}`;

		await firestore.collection("roles").where('name', '==', selectedRole.name.stringValue).get()
			.then(function (querySnapshot) {

				querySnapshot.forEach(function (doc) {
					firestore.collection("roles").doc(doc.id).update({
						name: rolePosition,
						updated_at: date1
					});
				});
			});

		getRolesPosition();
handleClose();
	}

	const deleteRolesPosition = async () => {
		setIsSavingOffice(true);
		await firestore.collection("roles").where('name', '==', selectedRole.name.stringValue).get()
			.then(function (querySnapshot) {

				querySnapshot.forEach(function (doc) {
					firestore.collection("roles").doc(doc.id).delete();
				});
			});
		getRolesPosition();
		handleClose();
	}

	const cancelRolesPosition = async () => {
		setIsEditRolePosition(0);
		setSelectedRole("");
		setRolePosition("")
	}

	return (
		<>

			<div className="card member-list">

				<div className={"card-header"}>
					<h4 className="card-title">Roles & Positions</h4>
					<button
						onClick={() => {
							cancelRolesPosition();
							setShow(true);
						}}
						className="btn btn-sm btn-primary btn-rounded"
					>
						New Role
					</button>
				</div>

				<div className="card-body">
					<NoData
						title={false}
						content={
							<div className="w-100 text-center text-muted">

								<div className="operator-details survey-section  text-center">
									<div className="form-group mb-1 mb-sm-5">
										<div className="mb-5 w-100">
											<PositionTable
												data={existingRoles}
												startEdit={startEditRoles}
											/>
										</div>
									</div>
								</div>
							</div>
						}
					/>
				</div>
			</div>


			<Modal show={show} size="lg" onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						<h3 className="">
							{isEditRolePosition == 0 && "New Role"}
							{isEditRolePosition == 1 && "Update Role"}
							{isEditRolePosition == 2 && "Delete Role"}
						</h3>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						isEditRolePosition == 2 ? <> <h4>Are you sure you want to delete this Role ? </h4></> : <>
							<div className="form-group mb-1 mb-sm-5 row">
								<div className="col-12">
                                    <label>Role </label>
									<input
										name="role&position"
										type="text"
										className="form-control"
										value={rolePosition}
										onChange={(event) =>
											setRolePosition(event.target.value)
										}
									/>
								</div>
							</div>
						</>
					}

				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					{isEditRolePosition != 0 && (
						<>
							{isSavingOffice ? (
								<Loader isLoading={true} />
							) : (
								<Button
									variant="primary"
									onClick={
										isEditRolePosition == 1
											? editRolesPosition
											: deleteRolesPosition
									}
								>
									{isEditRolePosition == 1 &&
										"Update Role"}
									{isEditRolePosition == 2 &&
										"Delete Role"}
								</Button>
							)}
						</>
					)}

					{isEditRolePosition == 0 &&
						(isSavingOffice ? (
							<Loader isLoading={true} />
						) : (
							<Button
								variant="primary"
								onClick={(event) => { createRolesPosition(); handleClose(); }}
							>
								Create Role
							</Button>
						))}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default RoleAndPosition;
