import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import useLocalStorage from "../../../utils/hooks/useLocalStorage";
import "./UplaodImage2.css";
import { generateString } from "../../../helpers/Utils";

const UploadImage = ({ index_on, file, current, section }) => {

	const [answers, setAnswers] = useLocalStorage('answers');
  let checkArr = answers[`section${section}`][current]?.img ? answers[`section${section}`][current]?.img : [];

  let checkImgArr = (typeof checkArr[index_on]=='undefined' || checkArr[index_on]==null)  ? [] : checkArr[index_on]; 

  const [images, setImages] = useState([]);
  const maxNumber = 2;
  const dataURLtoFile = (dataurl, filename) => {
 
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }

  useEffect(() => {
    checkImgArr.map(async item => {
      item.file = await dataURLtoFile(item.data_url,generateString(16));
      setImages(prevImg => [...prevImg,item])
    });
  },[]);

  const onChange = (imageList, addUpdateIndex) => {

    let list = JSON.parse(localStorage.getItem('answers'));
    if(typeof list[`section${section}`][current]==='undefined') {
      setAnswers((prevState) => ({...prevState,
        [`section${section}`]: [...prevState[`section${section}`], {img: [imageList]}] 
      }));
    } else {
      if(typeof list[`section${section}`][current].img ==='undefined') {
        let arr = [];
        arr[index_on] = imageList;
        list[`section${section}`][current] = {...list[`section${section}`][current], img: arr};
      } else {
        list[`section${section}`][current].img[index_on] = imageList;
      }
      setAnswers((prevState) => ({...prevState,
        [`section${section}`]: list[`section${section}`]}));
    }
    
    // data for submit
    setImages(imageList);
  };

  return (
    <div key={"file-" + index_on} className={"image-area mb-5"}>
      <div className="inner">
        <h5 className="text-capitalize mb-3">{file}</h5>
        <div className="App">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <div className="upload-image-operator">
                  <div className="form-group row px-5">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-12 col-sm-12">
                        <img
                          src={image.data_url}
                          alt=""
                          width="100%"
                          className="mb-3"
                        />
                        <div className="image-actions">
                          <div>
                            <button
                              className="btn  btn-secondary d-block w-100"
                              onClick={() => onImageUpdate(index)}
                            >
                              Change
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outline btn-error mt-4 d-block w-100 "
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {imageList.length == 0 ? (
                    <div>
                      {" "}
                      <button
                        className="btn w-100 pb-3"
                        onClick={onImageUpload}
                        style={isDragging ? { color: "red" } : null}
                        //   onClick={onImageUpload}
                        {...dragProps}
                      >
                        <h1 className="p-0">
                          <i className="icon-cloud-upload icons" />
                        </h1>
                        <b className="d-block">Tap to Add Image</b>
                      </button>
                      <button
                        className="btn d-none btn-secondary"
                        //   style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        //   {...dragProps}
                      >
                        Browse Images
                      </button>{" "}
                    </div>
                  ) : null}
                </div>
                {/* &nbsp; */}
                {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
              </div>
            )}
          </ImageUploading>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
