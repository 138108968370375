/* eslint-disable */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import helpers from "./helpers";

export const firebaseConfig = {
	apiKey: "AIzaSyCaQFN6uT__YzmdcslvXt-YBeaktbdsDdg",
	authDomain: "speedy-central.firebaseapp.com",
	databaseURL: "https://speedy-central-default-rtdb.firebaseio.com",
	projectId: "speedy-central",
	storageBucket: "speedy-central.appspot.com",
	messagingSenderId: "917163355081",
	appId: "1:917163355081:web:cd5e3bd8301a15e3ba1618",
	measurementId: "G-CFB2GPJFKN"
};


export const firebaseApp = firebase.apps.length === 0 ? firebase.initializeApp(firebaseConfig) : false;


export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

if (location.hostname === "localhost") {
	firebase.functions().useEmulator("localhost", 5001);
}

const googleProvider = new firebase.auth.GoogleAuthProvider();

const doesUserExist = async (email) => {
	const doc = await firestore.collection("users").where("email", "==", email).get();
	if (!doc.exists) {
		return false;
	} else {
		return true;
	}
};

const doesStaffExist = async (res) => {

	const date = new Date();
	const date1 = `${date.getFullYear()}/${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? 0 : ""}${date.getDate()}`;
	const userData = {
		"first_name": res.user.displayName.split(" ")[0],
		"last_name": res.user.displayName.split(" ")[1],
		"email": res.user.email,
		"position": '',
		"office": '',
		"created_at": date1,
		"updated_at": date1,
		"user_email": res.user.email,
		"short_code": '',
		"photo_url": res.photoURL ? res.photoURL : "https://i.imgur.com/ff8fLij.png",
		"approved": false,
		"status": 'enabled'
	}

	if (await doesUserExist(res.user.email)) {
		localStorage.setItem("user_email", userData.email);
	} else {
		firestore.collection("users").doc(res.user.uid).set(userData)
			.catch(function (error) {
				console.error("Error writing Value: ", error);
			});
	}

};

export const signInWithGoogle = () => {
	auth.signInWithPopup(googleProvider).then((res) => {
		doesStaffExist(res);
	});
};


export const userDetails = async (uid) => {
	var query = await (await firestore.collection("users").where("uid", "==", uid).get()).docs;
	const user = query[0];
	return user;
};

export const logOut = () => {
	return auth
		.signOut()
		.then(() => {
			window.location.href = "/";
			return true;
		})
		.catch((error) => {
			return false;

		});
};

export const UserController = {
	getAllUsers: async () => {
		const snapshot = await firestore.collection("users").get();
		let s = [];
		snapshot.forEach((doc) => {
			let u = doc.data();
			u.uid = doc.id;
			u.id = doc.id;
			if (u.profile) {
				s.push(u);
			}
			return doc.data();
		});
		localStorage.setItem("member_total", s.length);

		return s;
	},
	getUser: async (id) => {
		const doc = await firestore.collection("users").doc(id).get();
		if (!doc.exists) {
			return false
		} else {
			return doc.data();
		}
	},
	isLoggedIn: async () => {
		let x = auth.currentUser;
		console.log(
			"Init with user at " +
			(auth.currentUser ? auth.currentUser.email : " NONE")
		);

		return x;
	},
	updateUserProfile: async (update) => {
		if (functions) {
			const updateUserProfile = functions.httpsCallable("updateUserProfile");

			updateUserProfile({ update: update, uid: auth.currentUser.uid }).then(
				(result) => {
					const data = result.data;

				}
			);
		}
	}
};
export const QualityControlController = {
	getAllQualityControls: async () => {
		const snapshot = await firestore.collection("quality-control").get();
		let s = [];
		snapshot.forEach((doc) => {
			let u = doc.data();
			u.uid = doc.id;
			u.id = doc.id;
			s.push(u);

			return doc.data();
		});
		return s;
	},
	addUpdateQualityControl: async ({ name, title, description, image, id, deleted }) => {
		const data = {
			name,
			title,
			description,
			image,
			deleted: deleted ?? false
		};

		const res = await firebase.firestore()
			.collection("quality-control")
			.doc(id ?? helpers.alphanumeric(name))
			.set(data);
		return res;
	}, sendMailFun: async (mailOptions) => {
		if (functions) {
			const sendMail = functions.httpsCallable("sendMail");

			sendMail({ mailOptions }).then(
				(result) => {
					const data = result.data;
					console.log(data)
				}
			);
		}
	}
};
