/* eslint-disable */
import React, {useContext, useEffect  } from "react";
import { UserContext} from "../../../utils/providers/UserProvider";
import Globals from "../../../constants/values/Globals";
import HeaderUserMenu from "./HeaderUserMenu";
import GlobalNotifications from "../../../components/Plugins/Common/GlobalNotifications";
import GlobalSearchBar from "../../../components/Plugins/Common/GlobalSearchBar";
import Logo from "../../../assets/images/logos/logo.png";


const Header = ({ isAuth, profile, onNote, toggle, onProfile, onNotification }) => {
   const { user, sidebarClick } = useContext(UserContext);


   // var path = window.location.pathname.split("/");
   // var name = path[path.length - 1].split("-");
   // var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   // var finalName = filterName.includes("app")
   //    ? filterName.filter((f) => f !== "app")
   //    : filterName;
   return (
      <div className={"header" + (user?.role === 'public' ? " auth" : '') }>
         <div className="header-content">
            <div className="mobile logo"><img src={Logo} /></div>
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar"
                        style={{ textTransform: "capitalize" }}
                     >
                        {sidebarClick.includes("quality-control") || sidebarClick == "/" ? "quality control" : ""}
                        {sidebarClick.includes("user-setting") || sidebarClick.includes("role-position") || sidebarClick.includes("offices") ? "Settings" : ""}
                     </div>
                  </div>

          <ul className="navbar-nav header-right">
          {Globals.showHeaderSearch ? <GlobalSearchBar /> : null}
                     {Globals.showMessageNotifications ?  <MessageNotifications /> : null}
                 {Globals.showGlobalNotifications ?  <GlobalNotifications />: null}

                     <HeaderUserMenu toggle={toggle} profile={user} onProfile={onProfile} user={user} />
                  </ul> </div>
            </nav>
         </div>
      </div>
   );
};

export default Header;
