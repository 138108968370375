
import React, { Fragment } from "react";
import {
   Row,
   Col,
   Card,
   Table,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";
import spacetime from 'spacetime';


const BootstrapTable = (props) => {
  // console.log("props", props.data);
   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );

   return (
     <Fragment>
       <Table responsive={true} className="text-left">
         <thead>
           <tr>
             <th className="hide-mobile">
               <strong>#</strong>
             </th>
             <th>
               <strong>Role</strong>
             </th>
             <th className="hide-mobile">
               <strong>Updated</strong>
             </th>
             <th className="text-right"></th>
           </tr>
         </thead>
         <tbody>
           {props.data.map((item, index) => (
             <tr key={index}>
               <td className="hide-mobile">
                 <strong>{index + 1}</strong>
               </td>
               <td>{item.element.name.stringValue}</td>
               <td className="hide-mobile">
                 {spacetime(
                   item.element.updated_at.stringValue,
                   "America/New_York"
                 ).format("numeric-us")}
               </td>
               <td className="text-right">
                 <Dropdown>
                   <Dropdown.Toggle
                     variant="primary"
                     className="sharp outline-dark dark icon-false"
                   >
                     <i className="flaticon-381-edit"></i>
                   </Dropdown.Toggle>
                   <Dropdown.Menu>
                     <Dropdown.Item
                       onClick={() => props.startEdit(1, item.element)}
                     >
                       <button className="btn m-0 p-0">Edit</button>
                     </Dropdown.Item>
                     <Dropdown.Item
                       onClick={() => props.startEdit(2, item.element)}
                     >
                       <button className="btn m-0 p-0">Delete</button>
                     </Dropdown.Item>
                   </Dropdown.Menu>
                 </Dropdown>
               </td>
             </tr>
           ))}
         </tbody>
       </Table>
     </Fragment>
   );
};

export default BootstrapTable;
