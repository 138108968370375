import React, { useContext, Fragment, useState, useEffect } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "../assets/css/index.css";
import Nav from "../view/layouts/nav";
import Footer from "../view/layouts/Footer";
import Registration from "../view/pages/authentication/Registration";
import Login from "../view/pages/authentication/Login";
import ForgotPassword from "../view/pages/authentication/ForgotPassword";
import Error404 from "../view/pages/error/Error404";
import { UserContext } from "../utils/providers/UserProvider";
// eslint-disable-next-line
import { UserController } from "../utils/firebase";
import AdminHome from "./Plugins/Dashboard/Home/AdminHome";
import UserHome from "./Plugins/Dashboard/Home/UserHome";
import QualityControl from "./quality-control/List";
import Staff from "./Settings/Staff";
import RoleAndPosition from './Settings/RoleAndPosition';
import Location from "./Settings/Location";
// eslint-disable-next-line
import helpers from "../utils/helpers";


const Markup = () => {
  const { user } = useContext(UserContext);

  const [menuState, menuStateSet] = useState(1);
  const updateMenuState = (int) =>{
    console.log("parameter", int);
    menuStateSet(int);
  }
  // useEffect(() => {
  //   console.log("menustate", menuState);
  // }, [menuState])
  const Routes = {
    public: [
      /* FIRST ENTRY IS START PAGE */
      { url: "", component: Login },
      { url: "register", component: Registration },
      { url: "forgot-password", component: ForgotPassword },
    ],
    user: [
      /* FIRST ENTRY IS START PAGE */
      { url: "", component: QualityControl },
      { url: "quality-control", component: QualityControl },
      { url: "user-setting", component: Staff },
      { url: "role-position", component: RoleAndPosition },
      { url: "offices", component: Location },
    ],
    admin: [
      /* FIRST ENTRY IS START PAGE */
      { url: "", component: QualityControl},
      { url: "quality-control", component: QualityControl },
      { url: "user-setting", component: Staff },
      { url: "role-position", component: RoleAndPosition },
      { url: "offices", component: Location },
      
    ],
    global: [{ url: "error-404", component: Error404 }],
  };

  const Permission = user.role ? user.role : "public";

  return user.initialized ? (
    <Router basename="/">
      <div id="main-wrapper" className={"show" + (Permission === "public" ? " auth" : "")}>
        <Nav setMenuState={updateMenuState}/>
        <div className="content-body">
          <div className="container-fluid">
            <Switch>
              {Routes[Permission].map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                  // component={() => <data.component  />}
                  
                />
              ))}
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  ) : (
    <></>
  );
};


export default Markup;
