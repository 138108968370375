import React, {useState} from "react";
import checkmark from "../../../assets/images/check.svg";
import copy from "../../../assets/images/copy_5.svg";
import { options } from '../../../constants/data/defaults';


export function Complete({onDone, onClone, finalData, onSubmitResult}) {
const [nextOperator, setNextOperator] = useState(false);  
const [showOperator, setShowOperator] = useState(false);

const handleChange = (e) => {
    setNextOperator(e.target.value);
}

const handleAddOperator = () => {
  let updatedData = finalData;
  updatedData = {...updatedData, operator: nextOperator}
 onClone(updatedData);
  setShowOperator(false);

}

return <div className="survey-complete animated animatedFadeInUp fadeInUp d-table-cell">

{!showOperator ? <div><img src={checkmark} className="w-25"/><button className="btn btn-primary mt-5 mb-5 w-100" onClick={onDone}>Done</button>
 <button onClick={()=>{setShowOperator(true)}} className="d-none btn btn-light text-dark w-100 copy-btn"><img className="w-auto h-100" src={copy} />Copy for Additional Operator</button></div> :  <div className="col-12">
     <h5 className="mb-5">Copy for Additional Operator</h5>
          <label>Operator Inspected</label>
          <select
            defaultValue={0}
            onChange={handleChange}
            className="form-control"
            name="operator"
          >
            {options.operators.map((el) => (
              // <option key={el.id} value={el.id}>
              <option key={el.id} value={el.id + ' - ' + el.name.split(',').reverse().join(' ')}>
                {el.id + ' - ' + el.name.split(',').reverse().join(' ')}
              </option>
            ))}
            <option value={0}>-</option>
          </select>

          {/* {nextOperator ? <button className="btn btn-primary mt-3 mb-5 w-100" onClick={onDone}>Copy</button> : null} */}
          {nextOperator ? <button className="btn btn-primary mt-3 mb-5 w-100" onClick={handleAddOperator}>Copy</button> : null}
          {/* <button className="btn btn-link text-dark" onClick={onDone}>Cancel</button> */}
          <button className="btn btn-link text-dark" onClick={() =>{setShowOperator(false)}}>Cancel</button>
        </div>}

</div>

}