import React from "react";


const NoData = ({title, content, isLoading, card})=> {


return <div className={"no-data" + (card ? " card member-list hide-while-loading" : "")} >
    
{title ? <div><h4>{title}</h4></div> : null}

{content ? <div>{content}</div> : ''}

</div>


}

export default NoData;