
import React from 'react';

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateString = (length) => {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getRealId = (draggableId) => {
  return draggableId.split('.')[0] * 1;
};

export const truncateString = (str, n) => {
  if (str.length > n) {
    return str.substring(0, n) + '...';
  } else {
    return str;
  }
};
export const moneyFormat = (value) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  
 return formatter.format(value);
}
export const replaceVariables = (str, obj) => {
  let keys = Object.keys(obj);
  let n = str;

  for (var i = 0; i < keys.length; i++) {
    let k = keys[i];
    let root = k;

    let val = obj[k];
    let variable = typeof val !== 'object' ? false : '[' + root + ']';
    if (typeof val === 'object') {
      root = k;
      let variables = Object.keys(k[root]);
      for (var ii = 0; ii < variables.length; ii++) {
        let z = variables[ii];
        let v = '[' + root + '_' + z + ']';
        let val = obj[root][z];
        n = n.replace(v, val);
      }
    } else {
      n = n.split(variable).join(val);
    }
  }

  return n;
};
export const capitalize = (str) => {
  try {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  } catch (e) {}
};

export const lowercaseAll = function (obj) {
  Object.keys(obj).forEach((k) => {
    if (
      typeof obj[k] === 'string' &&
      k.toLowerCase().indexOf('id') !== k.length - 2 &&
      k.toLowerCase().indexOf('note') < 0
    ) {
      obj[k] = obj[k].toLowerCase();
    } else if (typeof obj[k] === 'object') {
      lowercaseAll(obj[k]);
    } else {
    }
  });
  return obj;
};
export const onDragEnd = (event) => {
  const { source, destination, draggableId } = event;
  if (
    destination?.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }
  if (!destination || !destination.canDropped) {
    return;
  }
  this.setState((prevState) => {
    if (source.droppableId !== destination.droppableId) {
      let srcItems = [...prevState[source.droppableId]];
      let dstItems = [...prevState[destination.droppableId]];
      const id = getRealId(draggableId);
      const existedItem = dstItems.find((item) => item.id === id);
      if (existedItem) {
        return null;
      }
      const draggableItem = srcItems[source.index];
      if (source.config.copyMode) {
        srcItems = prevState[source.droppableId];
      } else {
        srcItems.splice(source.index, 1);
      }
      dstItems.splice(destination.index, 0, draggableItem);
      return {
        [source.droppableId]: srcItems,
        [destination.droppableId]: dstItems,
      };
    } else {
      const items = [...prevState[source.droppableId]];
      const draggableItem = items[source.index];
      items.splice(source.index, 1);
      items.splice(destination.index, 0, draggableItem);
      return {
        [source.droppableId]: items,
      };
    }
  });
};
export const buildDropdown = (data, value, key, onChange, disabled = true) => {
  if (typeof data !== 'undefined')
    return (
      <select
        defaultValue={value}
        className={'form-control' + (disabled ? ' disabled' : '')}
        name={key}
        key={key + data.length}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {data.map((x, y) => (
          <option key={y} value={x.value}>
            {x.label}
          </option>
        ))}
      </select>
    );
};
export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = (date, format, isDateObject) => {
  if(!date)
    return null;
  if(!isDateObject){
    date = new Date(date);
  }
  let dd = date.getDate();
  let mm = date.getMonth() + 1; // January is 0!

  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  switch(format){
    case 'YYYY-MM-DD':
      return `${yyyy}-${mm}-${dd}`;
    case 'MM/DD/YYYY':
      return `${mm}/${dd}/${yyyy}`;
    default: 
      return `${dd}.${mm}.${yyyy}`;
  }
};

export const getCurrentTime = (date, format, isDateObject) => {
  if(!date)
    return null;
  if(!isDateObject){
    date = new Date(date);
  }
  let hh = date.getHours();
  let mm = date.getMinutes();
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${hh}:${mm}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};
export const alphanumeric = (str) => {
  return str.replace(/[^a-z0-9]/gi, '');
};
export const camelCase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

export const fixProjectKeys = (obj) => {
  const keys = Object.keys(obj);
  for (var i = 0; i < keys.length; i++) {
    let oldKey = keys[i];
    let newKey = '';

    if (oldKey.indexOf('GC:') > -1) {
      newKey =
        'gc' +
        capitalize(oldKey.replace('GC: ', '').toLowerCase()).replace(
          /[^A-Za-z0-9]/g,
          ''
        );
    } else {
      //newkey is just camelcase
      newKey = camelCase(oldKey.toLowerCase());
    }
    if (oldKey !== newKey) {
      Object.defineProperty(
        obj,
        newKey,
        Object.getOwnPropertyDescriptor(obj, oldKey)
      );

      delete obj[oldKey];
    }
  }

  return obj;
};
export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getURLSearchParams = (search) => {
  if(!search)
   return {};
  return search.substring(1).split("&").reduce(function(result, value) {
    var parts = value.split('=');
    if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    return result;
  }, {});
}