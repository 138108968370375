/* eslint-disable */
import React, { useEffect, useState } from "react";
import { QualityControlController } from "../../utils/firebase";
import { useHistory } from "react-router-dom";
import Nav from "../../view/layouts/nav";
import Footer from "../../view/layouts/Footer";
import PageTitle from "../../view/layouts/PageTitle";
import QualityControlList from "./QualityControlList";
import QualityControlModal from "./QualityControlModal";

const QualityControl = (props) => {

  const { user } = props;
  
  const [qualityControl, setQualityControl] = useState([]);
  const [createSurvey, setCreateSurvey] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [activeQualityControl, setActiveQualityControl] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const editQualityControl = (data) => {
    //Show Modal for editing the qualityControl
    setActiveQualityControl(data);
    setModalOpen(true);
  }
  const toggleModal = () => {
    if (modalOpen) {

      setActiveQualityControl(false);

      //update qualityControl,
      updateQualityControlList();
    }
    setModalOpen(!modalOpen);
  }
  const updateQualityControlList = async () => {
    setIsLoading(true);
    setDataFetched(false);
    const u = await QualityControlController.getAllQualityControls();
    setQualityControl(u);
    setTimeout(() => {
      setIsLoading(false);
      setDataFetched(true);
    }, 1000);

  }

  const history = useHistory();
  const isAuth = false;
  useEffect(() => {

    return (async () => {
      const u = await QualityControlController.getAllQualityControls();
      setQualityControl(u);
      setTimeout(() => {
        setIsLoading(false);
        setDataFetched(true);
      }, 1000);

    })();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          {createSurvey ? <QualityControlModal /> : <QualityControlList
            qualityControl={qualityControl}
            isLoading={isLoading}
            dataFetched={dataFetched}
            activeQualityControl={activeQualityControl}
            modalOpen={modalOpen}
            toggleModal={toggleModal}
            toggleActiveQualityControl={setActiveQualityControl}
          />}
        </div>
      </div>
    </>
  );
};

export default QualityControl;
