

export const options = {
  leadSources: [
    { value: 'Dodge-McGraw Hill', label: 'Dodge-McGraw Hill' },
    { value: 'CDC', label: 'CDC' },
    { value: 'Bluebook', label: 'Bluebook' },
    { value: 'Dispatch Lead', label: 'Dispatch Lead' },
    { value: 'Referral', label: 'Referral' },
    { value: 'Networking Event', label: 'Networking Event' },
    { value: 'Other', label: 'Other' }
  ],
  referralSource: [
    { value: 'Existing Customer/Contact', label: 'Existing Customer/Contact' },
    { value: 'Operator', label: 'Operator' },
    {
      value: 'Other Concrete Cutting Company',
      label: 'Other Concrete Cutting Company',
    },
    { value: 'Networking Event', label: 'Networking Event' },
  ],
  companyAddress: [
    { value: 'address1', label: 'address1' },
    { value: 'address2', label: 'address2' },
  ],
  contactMethodTypes: ['email', 'phone', 'fax'],
  companyTypes: [
    { value: 'GC', label: 'GC' },
    { value: 'Plumbing', label: 'Plumbing' },
    { value: 'Electrical', label: 'Electrical' },
    { value: 'HVAC', label: 'HVAC' },
    { value: 'Structural/Masonry', label: 'Structural/Masonry' },
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Demolition', label: 'Demolition' },
    { value: 'Other', label: 'Other' },
  ],
  locationTypes: [
    { value: 'Office', label: 'Office' },
    { value: 'Jobsite', label: 'Jobsite' },
    { value: 'Networking Event', label: 'Networking Event' },
    { value: 'Customer Goodwill Event Scheduled/Pending', label: 'Customer Goodwill Event Scheduled/Pending'}
  ],
  NetworkingAffiliations: [
    { value: 'CASF', label: 'CASF' },
    { value: 'ABC', label: 'ABC' },
    { value: 'AGC', label: 'AGC' },
    { value: 'FTBA', label: 'FTBA' },
    { value: 'LBA', label: 'LBA' },
    { value: 'Other', label: 'Other' },
    { value: 'None', label: 'None' },
  ],
  eventTypes: ['CASF', 'ABC', 'AGC', 'FTBA', 'LBA', 'Other'],
  industrySectors: [
    { value: 'Single Family Residential', label: 'Single Family Residential' },
    { value: 'Multi Family Residential', label: 'Multi Family Residential' },
    { value: 'Commercial Building', label: 'Commercial Building' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Hospitality', label: 'Hospitality' },
    {
      value: 'Underground/Site Development',
      label: 'Underground/Site Development',
    },
    { value: 'Power & Water', label: 'Power & Water' },
    { value: 'Transportation', label: 'Transportation' },
    { value: 'Military', label: 'Military' },
    { value: 'Hospitals & Healthcare', label: 'Hospitals & Healthcare' },
    { value: 'Education', label: 'Education' },
    { value: 'Industrial', label: 'Industrial' },
    { value: 'Logistics', label: 'Logistics' },
  ],
  positions: [
    { value: 'Owner/President', label: 'Owner/President' },
    { value: 'Vice President', label: 'Vice President' },
    { value: 'Project Manager', label: 'Project Manager' },
    { value: 'Super Intendent', label: 'Super Intendent' },
    { value: 'Assistant', label: 'Assistant' },
    { value: 'Other', label: 'Other' },
  ],
  actions: [
    { value: 'Sales/Cold call', label: 'Sales/Cold call' },
    { value: 'Scheduled Appointments', label: 'Scheduled Appointments' },
    { value: 'Customer Goodwill Event', label: 'Customer Goodwill Event' }
  ],
  actionResults: [
    { value: 'New/existing contact', label: 'New/existing contact' },
    { value: 'Proposal generated', label: 'Proposal generated' },
    { value: 'Work sold/scheduled', label: 'Work sold/scheduled' },
    { value: 'Customer Goodwill Event Scheduled/Pending', label: 'Customer Goodwill Event Scheduled/Pending' },
  ],
  actionEvents: [
    { value: 'Sub Meeting', label: 'Sub Meeting' },
    { value: 'Happy Hour', label: 'Happy Hour' },
    { value: 'Lunch & Learn', label: 'Lunch & Learn' },
    { value: 'Fishing', label: 'Fishing' },
    { value: 'Other', label: 'Other' },
    { value: 'QAQC', label: 'QAQC' },
  ],
  offices: [{name:"FTL", id:"F"},{name:"ORL", id:"C"},{name:"TAM", id:"CT"},{name:"JAX", id:"J"}],
  operators: [{id:"F1",name:"Allison,Coy"},
  {id:"F2",name:"Chiaverini,Mike"},
  {id:"F3",name:"Cole,Cary"}, 
  {id:"F4",name:"Forrest,Arthur"},
  {id:"F5",name:"Francis,Dave"},
  {id:"F6",name:"Frank,Jason"}, 
  {id:"F7",name:"Jeff,Cameron"},
  {id:"F8",name:"Johnson,Garfield"}, 
  {id:"F9",name:"Montesino,Remsis"}, 
  {id:"F10",name:"Pressley,Mark"}, 
  {id:"F11",name:"Sainz,Danny"}, 
  {id:"F12",name:"Stokes,John"}, 
  {id:"F13",name:"Swaby,Keitho"}, 
  {id:"F14",name:"Sweney,Cedric"}, 
  {id:"F15",name:"Watkins,Lavelle"}, 
  {id:"F16",name:"Whitely,Kevin"}],
  inspectors: [{id:"F50", name: "Todd Barna"},{id:"F51", name: "Todd Livingston"},{id:"F52", name:"Nicole Fernandez"},{id:"F53", name:"Brian Ward"},{id:"F54", name: "Miguel Chacon"},{id: "F55", name:"Andy Saunders"}],
  projects: ['project default1', 'project default2'],
  companies: ['company default1', 'company default2'],
  notes: [
    {
      name: 'Chris Conway',
      message:
        'Spoke to jamie in billing and she said she would reach out after the holidays.',
      date: new Date(2021, 1, 6, 14, 34, 0),
    },
    {
      name: 'Chris Conway',
      message: 'Called to speak about past due balance, no one available.',
      date: new Date(2021, 1, 3, 9, 34, 0),
    },
    {
      name: 'Jane Mckenna',
      message: 'Client Invoiced for Project GR-598254.',
      date: new Date(2020, 12, 4, 13, 34, 0),
    },
  ],
  salesEvents: [
    {
      id: '1',
      companyName: 'company 1',
      followUpDate: '2021-04-10',
      leadsource: 'person 1',
      note: 'sometext 1',
    },
    {
      id: '2',
      companyName: 'company 2',
      startDate: '2021-04-04',
      leadsource: 'person 2',
      followUpDate: '2021-04-12',
      note: 'sometext 2',
    },
    {
      id: '3',
      companyName: 'company 3',
      followUpDate: '2021-04-10',
      leadsource: 'person 3',
      note: 'sometext 3',
    },
  ],
};
