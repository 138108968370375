/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../Plugins/Common/Loader";
import NoData from "../Plugins/Common/Tables/NoData";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import OfficeTable from "../Tables/OfficeTable";
import { firestore } from '../../utils/firebase';

const Location = () => {

    // const userEmail = localStorage.getItem('user_email');
    // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // const [showGenerateButton, setShowGenerateButton] = useState(false);
    // const [isGettingData, setIsGettingData] = useState(false);
    const [officeName, setOfficeName] = useState('');
    const [shortCode, setShortCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [office, setOffice] = useState([]);
    const [isSavingOffice, setIsSavingOffice] = useState(false);
    const [isEdit, setIsEdit] = useState(0);
    const [selected, setSelected] = useState('');
    // const [firstdayOfMonth, setFirstdayOfMonth] = useState(0);
    // const [yearArray, setYearArray] = useState([]);
    // const [officeToReport, setOfficeToReport] = useState('');
    // const [startReportOffice, setStartReportOffice] = useState(false);
    // const [selectedMonth, setSelectedMonth] = useState("");
    // const [selectedYear, setSelectedYear] = useState(0);
    // const [reportInspector, setReportInspector] = useState([]);
    // const [reportOperator, setReportOperator] = useState([]);
    // const [reportTableData, setReportTableData] = useState([]);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

    // const getYearArray = (year) => {
    //     return Array(11).fill().map((_, idx) => year - idx);
    // }

    let nowDate = new Date();
    // const presentYear = nowDate.getFullYear();

    nowDate = nowDate.toISOString().substring(0, 10);
    let tempOffices = [];

    useEffect(() => {
        getOffice();
        // setYearArray(getYearArray(presentYear));
    }, []);

    // useEffect(() => {
    //     // getWeeklyInterval();
    //     if (selectedYear !== 0 && selectedMonth !== "") {
    //         setShowGenerateButton(true);
    //     }
    //     else {
    //         setShowGenerateButton(false);
    //     }
    // }, [selectedYear, selectedMonth])

    // const getWeeklyInterval = () => {
    //     let date = Date.parse(`02 ${selectedMonth.substring(0, 3)} ${selectedYear} 00:00:00 GMT`);
    //     date = new Date(date);
    //     date = date - (date.getDay() - 1) * 86400000;
    //     setFirstdayOfMonth(date);
    // }

    const startEdit = (int, object) => {
        handleShow();
        setIsEdit(int);
        setOfficeName(object.name.stringValue);
        setShortCode(object.short_code.stringValue);
        setPhoneNumber(object.phone_number.stringValue);
        setSelected(object);

    }

    const cancelEditOffice = () => {
        setOfficeName('');
        setShortCode('');
        setPhoneNumber('');
        setSelected("");
        setIsEdit(0);
    }

    const deleteOffice = async () => {
        setIsSavingOffice(true);
        await firestore.collection("office").where('name', '==', selected.name.stringValue).get()
            .then(function (querySnapshot) {

                querySnapshot.forEach(function (doc) {
                    firestore.collection("office").doc(doc.id).delete();
                });
            });
        getOffice();
        handleClose();
    }

    const manageOffices = async () => {

        setIsSavingOffice(true);
        if (officeName == "" || shortCode == "" || phoneNumber == "") {
            alert("Please fill all the fields");
            setIsSavingOffice(false);
            return;
        }

        const date = new Date();
        const date1 = `${date.getFullYear()}/${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? 0 : ""}${date.getDate()}`;

        const office = {
            "name": officeName,
            "short_code": shortCode,
            "phone_number": phoneNumber,
            "email": localStorage.getItem('user_email'),
            "created_at": date1,
            "updated_at": date1,
            "staffs": []
        }
        const officeDataWithName = await firestore.collection("office").where('name', '==', office.name).get();
        if (officeDataWithName._delegate._snapshot.docChanges.length > 0) {
            alert("Office Name must be unique!");
            setIsSavingOffice(false);
            return;
        }

        const officeDataWithCode = await firestore.collection("office").where('short_code', '==', office.short_code).get();
        if (officeDataWithCode._delegate._snapshot.docChanges.length > 0) {
            alert("Short code must be unique!");
            setIsSavingOffice(false);
            return;
        }

        const officeDataWithPhone = await firestore.collection("office").where('phone_number', '==', office.phone_number).get();
        if (officeDataWithPhone._delegate._snapshot.docChanges.length > 0) {
            alert("Phone Number must be unique!");
            setIsSavingOffice(false);
            return;
        }

        firestore.collection('office').doc().set(office);
        getOffice();
          handleClose();
    }

    const getOffice = async () => {
        tempOffices = [];
        const email = localStorage.getItem('user_email');
        const officeData = await firestore.collection("office").where('email', '!=', "email").get();
        const filteredOfficedata = officeData._delegate._snapshot.docChanges;
        for (let index = 0; index < filteredOfficedata.length; index++) {
            const element = filteredOfficedata[index].doc.data.value.mapValue.fields;
            tempOffices[index] = { element };
        }

        setOffice(tempOffices);
        setIsSavingOffice(false);
        setOfficeName('');
        setShortCode('');
        setPhoneNumber('');
        setIsEdit(0);
        setSelected("");
    }

    const onHandleChangePhoneNumber = (event) => {
        setPhoneNumber(event);
    }

    const editOffice = async () => {
        setIsSavingOffice(true);

        if (officeName == "" || shortCode == "" || phoneNumber == "") {
            alert("Please fill all the fields");
            setIsSavingOffice(false);
            return;
        }

        const officeDataWithName = await firestore.collection("office").where('name', '==', officeName).get();
        if (officeDataWithName._delegate._snapshot.docChanges.length > 0) {
            const officeNameToCompared1 = officeDataWithName._delegate._snapshot.docChanges[0].doc.data.value.mapValue.fields.name.stringValue;
            if (officeNameToCompared1 != selected.name.stringValue) {
                alert("Office Name must be unique!");
                setIsSavingOffice(false);
                return;
            }
        }

        const officeDataWithCode = await firestore.collection("office").where('short_code', '==', shortCode).get();
        if (officeDataWithCode._delegate._snapshot.docChanges.length > 0) {
            const officeNameToCompared2 = officeDataWithCode._delegate._snapshot.docChanges[0].doc.data.value.mapValue.fields.name.stringValue;
            if (officeNameToCompared2 != selected.name.stringValue) {
                alert("Short code must be unique!");
                setIsSavingOffice(false);
                return;
            }
        }

        const officeDataWithPhone = await firestore.collection("office").where('phone_number', '==', phoneNumber).get();
        if (officeDataWithPhone._delegate._snapshot.docChanges.length > 0) {
            const officeNameToCompared3 = officeDataWithPhone._delegate._snapshot.docChanges[0].doc.data.value.mapValue.fields.name.stringValue;
            if (officeNameToCompared3 != selected.name.stringValue) {
                alert("Phone Number must be unique!");
                setIsSavingOffice(false);
                return;
            }
        }

        const date = new Date();
        const date1 = `${date.getFullYear()}/${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? 0 : ""}${date.getDate()}`;

        await firestore.collection("office").where('name', '==', selected.name.stringValue).get()
            .then(function (querySnapshot) {

                querySnapshot.forEach(function (doc) {
                    firestore.collection("office").doc(doc.id).update({
                        name: officeName,
                        short_code: shortCode,
                        phone_number: phoneNumber,
                        updated_at: date1
                    });
                });
            });
        getOffice();
        handleClose();
    }

    // const startOfficeReport = (officeName) => {
    //     setOfficeToReport(officeName);
    //     setStartReportOffice(true);
    // }

    // const confirmExportData = () => {
    //     setStartReportOffice(false);
        // setReportInspector([]);
    //     setReportOperator([]);
        // setReportTableData([]);
    //     setSelectedMonth("");
    //     setSelectedYear(0);
    // }

    return (
        <>
            <div className="card member-list">
                <div className={"card-header"}>
                    <h4 className="card-title">Offices</h4>
					<button
						onClick={() => {
							cancelEditOffice();
							setShow(true);
						}}
						className="btn btn-sm btn-primary btn-rounded"
					>
						New Office
					</button>
                </div>

                <div className="card-body">
                    <NoData
                        title={false}
                        content={
                            <div className="w-100 text-center text-muted">
                                <div className="operator-details survey-section  text-center">
                                    <div className="form-group mb-1 mb-sm-5">
                                        <div className="mb-5 w-100">
                                            <OfficeTable data={office} startEdit={startEdit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>


            <Modal show={show} size="lg" onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 className="">
                            {isEdit == 0 && "New Office"}
                            {isEdit == 1 && "Update Office"}
                            {isEdit == 2 && "Delete Office"}
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isEdit == 2 ? <> <h4>Are you sure you want to delete this Office ? </h4></> : <>
                            <div className="form-group mb-1 mb-sm-5 row">
                                <div className="col-12">
                                    <label>Office Name </label>
                                    <input
                                        name="office_name"
                                        type="text"
                                        className="form-control"
                                        value={officeName}
                                        onChange={(event) =>
                                            setOfficeName(event.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1 mb-sm-5 row">
                                <div className="col-12  col-sm-6">
                                    <label>Short Code</label>
                                    <input
                                        type="text"
                                        name="short_code"
                                        value={shortCode}
                                        className="form-control"
                                        onChange={(event) =>
                                            setShortCode(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-12  col-sm-6">
                                    <label>Phone Number</label>
                                    <PhoneInput
                                        country={"us"}
                                        value={phoneNumber}
                                        inputClass="form-control"
                                        inputProps={{
                                            name: "phone",
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        onChange={(event) => {
                                            onHandleChangePhoneNumber(event);
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    {isEdit != 0 && (
                        <>
                            {isSavingOffice ? (
                                <Loader isLoading={true} />
                            ) : (
                                <Button
                                    variant="primary"
                                    onClick={
                                        isEdit == 1 ? editOffice : deleteOffice
                                    }
                                >
                                    {isEdit == 1 && "Update Office"}
                                    {isEdit == 2 && "Delete Office"}
                                </Button>
                            )}
                        </>
                    )}

                    {isEdit == 0 &&
                        (isSavingOffice ? (
                            <Loader isLoading={true} />
                        ) : (
                            <Button
                                variant="primary"
                                onClick={(event) => { manageOffices(); handleClose(); }}
                            >
                                Create Office
                            </Button>
                        ))}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Location;
