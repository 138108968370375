/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import img from "../../../assets/images/logos/logo.svg";
import UserContext from "../../../utils/providers/UserProvider"
import { signInWithGoogle, UserController } from "../../../utils/firebase";
import { useHistory } from 'react-router-dom';
import Nav from '../../../view/layouts/nav';
import Footer from '../../../view/layouts/Footer'
import Loader from "../../../components/Plugins/Common/Loader";

const Login = (props) => {
   // const { user } = useContext(UserContext);

   return (

      <div className="authentication-content">
         <div className="row no-gutters">
            <div className="col-xl-12  text-center">
               <img src={img} className="auth-logo w-50" alt="" />
               <div className="card auth-form h-auto bg-white mt-5 p-0 shadow">
                  <div className="p-4 p-md-5 card-body text-left">
                     <div className="text-muted text-medium">Speed Concrete Cutting</div>
                     <h2 className="w-100 text-left mt-2 mb-0 p-0">Employee Login</h2>

                  </div> <div className="p-4 p-md-5 card-body text-center bg-beige d-block flex-none">
                     <button className="btn btn-dark btn-block google-sign-in" onClick={signInWithGoogle}>
                        <div className="google-img-container"><img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" /></div> <span> Continue with Google</span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

   );
};

export default Login;
