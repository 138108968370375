import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import {  questions } from './components/data';
import Questions from './components/Questions';
import Form from './components/Form';
import { Acknowledgement } from "./components/acknowledgement";
import { Complete } from "./components/complete";
import useLocalStorage from 'use-local-storage-state';
import { firestore, QualityControlController } from '../../utils/firebase'; 
import { drawDOM, exportPDF } from "@progress/kendo-drawing";

const QualityControlModal = ({ qualityControl, toggleModal, modalOpen, getSubmittedData, clearForm }) => {
	const [surveyComplete, setSurveyComplete] = useState(false);

	const [current, setCurrent] = useState(0);
	const [section, setSection] = useState(1);
	const [canStart, setCanStart] = useState(false);
	const [showForm, setShowForm] = useState(true);
	const [showQuestions, setShowQuestions] = useState(false);
	const [showAcknowledgement, setShowAcknowledgement] = useState(false);
	const [readySubmit, setReadySubmit] = useState(false);
	const [finalData, setFinalData] = useState("");
	const [pdf, setPdf] = useState(null);
	const [base64, setBase64] = useState('');
	const [isConfirmSignature, setIsConfirmSignature] = useState(false);
	const [clearSignature, setClearSignature] = useState(false);
	const [points1, setPoints1] = useState(0);
	const [points2, setPoints2] = useState(0);

	const [formData, setFormData] = useLocalStorage("formData",{ defaultValue: {
      project_name: "",
      date: new Date(),
    }});

	const [answers, setAnswers] = useLocalStorage("answers",{ defaultValue: {
    	section1: [],
    	section2: [],
  	}});

	useEffect(() => {
		if(answers.section1 === undefined) {
			setAnswers({
				section1: [],
				section2: [],
			});
		}
	}, []);

	const startClone = (newOperator) => {
		let f = formData;
		f.operator = newOperator;
		restart(f);
	}

	const restart = () => {
		setShowAcknowledgement(false);
    	setShowQuestions(false);
		setShowForm(true);
		setFormData(f);
	}
	useEffect(() => {
    let reject = false;
	
    //spacetime(selectedData.date, 'America/New_York').format('numeric-us')
		try {
			Object.keys(formData).map((el) => {
				
        if (
          el !== "date" &&
          el !== "user" &&
          el !== "answers" &&
          (!(formData[el].length > 0) || formData[el] == "0")
        ) {
          reject = true;
        }
      });

      if (Object.keys(formData).length > 6 && !reject) {
        setCanStart(true);
      } else {
        //  setCanStart(false);
        setCanStart(true);
      }
  } catch (e) {
	  
	  console.log(e);
		
	}
  }, [formData]);

	// const handleUploadError = error => {
	// 	setProfile({ isUploading: false });
	// 	console.error(error);
	// };

	const updateData = (e) => {
		let key = e.target.name;
		let val = e.target.value;


		let c = formData;
		c[key.toLowerCase()] = val;
		setFormData(c);
		let reject = false;

		Object.keys(formData).map((el) => {
			if ((el !== 'date' && el !== 'answers') && (!(formData[el].length > 0) || formData[el] == '0')) {
				reject = true;
			}
		});

		if (Object.keys(formData).length > 6 && !reject) {
			setCanStart(true);
		} else {
			//  setCanStart(false);
			setCanStart(true);
		}
	};

	const setPdfData = (data) => {
		
		drawDOM(data.current.rootElForPDF, {
			paperSize: "A2",
			margin: "2cm"
		  })
			.then((group) => {
			  return exportPDF(group);
			})
			.then((dataUri) => {
				setBase64(dataUri.split(',')[1]);
			});
			
		setPdf(data);
	}

	const handlePageChange = (v) => {

		let ansHook = JSON.parse(localStorage.getItem('answers'));

		//for files required validation
		if (questions[section - 1].questions[current]?.files != undefined &&
			questions[section - 1].questions[current]?.files_required === true &&
			 v == 1) {
			if(typeof ansHook[`section${section - 1}`][current]?.img === 'undefined') return false;
			
			for (let index = 0; index < questions[section - 1].questions[current]?.files.length; index++) {
				if(typeof ansHook[`section${section - 1}`][current].img[index] === 'undefined') return false;
				if(ansHook[`section${section - 1}`][current].img[index]===null || ansHook[`section${section - 1}`][current].img[index].length===0) return false;
			}
		}

		if(ansHook[`section${section - 1}`][current] === undefined && v !== -1) {
			return false;
		}

		let next = current + v;
		if (section == 2 && next == 8) {
			setSection(3);
			next = 0;
		}


		if(v < 0 && section == 3 && current == 0) {
			setSection(2);
			next = 7;
		}
		
		if (v < 0 && section == 2 && current == 0) {
			setSection(1);
			setShowQuestions(false);
			setShowForm(true);
		}

		if (v > 0 && current == 14) {
			setShowAcknowledgement(true);
			setShowQuestions(false);
		}
		setCurrent(next);

		document.getElementById("qaqcBody").scroll({ top: 0, behavior: "smooth" });
	};

	const mailText =  (name, question,score) => {
		return `Hello there,  <br />Operator: ${name}<br /> 
				<p>New QAQC submited, Question: ${question}, score: ${score}.</p> 
				<br /> Thank You!`;
	}

	const onSubmitResult = async (data_save) => {
		try {
				const staffData = await firestore.collection("users").where('office', '==', data_save.scc_office).where('position', '==', 'Manager').get();
				const mailArray = await staffData.docs.map(doc => doc.data().email);
				const mailOptions = {
					to: mailArray, // Need to send mail to management
					subject: 'Operations Manager', // email subject
					html: '', // email content in HTML
					attachments: [
						{   // encoded string as an attachment
							filename: 'QAQC_Survey.pdf',
							content: base64,
							encoding: 'base64'
						},
					]
				};
			data_save.answers.section2.map(async (item, key) => { 
				
				try {

					if (item) {
						if (item && typeof (item.response) === "number") {
							if (item.response <= 3) {
								mailOptions.html = mailText(data_save.operator, questions[1]?.questions[key].question, item.response);
								// QualityControlController.sendMailFun(mailOptions);
								return;
							}
						}
					}
				} catch (e) {
					console.log(e);
				}
			});	
			data_save.answers.section3.map(async(item, key) => { 
				try {

					if (item) {
						if (item.response === "no") {
							mailOptions.html = mailText(data_save.operator, questions[2]?.questions[key].question, 0);
							// QualityControlController.sendMailFun(mailOptions);
							return;
						}
					}
				} catch (e) {
					console.log(e);
				}
			});
		} catch (e) {
			console.log(e);
		}
		await firestore.collection('qaqc_entries').doc().set({ "data": JSON.stringify(data_save) });
			//after final submission
			setAnswers({
				section1: [],
				section2: [],
		  	});

		  	setFormData({
				project_name: "",
				date: new Date(),
		  	});

		const date = new Date();

		const qaqcAbstract = {
			points: points1 + points2,
			operator: data_save.operator,
			inspector: data_save.inspector,
			office: data_save.scc_office,
			date: date,
		}

		await firestore.collection('qaqc_abstract').doc().set(qaqcAbstract);
	}
	const setAddiData = (data) => {
		let formData = JSON.parse(localStorage.getItem('formData'));
		formData.answers = { "section2": data.section1, "section3": data.section2 };
		formData.operatorSignature = data.operatorSignature;
		formData.inspectorSignature = data.inspectorSignature;
		formData.user = JSON.parse(localStorage.getItem("userData"))?.email;
		setFinalData(formData);
	}

	const onCloseFrom = () => {
		toggleModal(false);
	}

	return (
		<Modal className="fade h-100 modal-survey quality-control-module" show={modalOpen}>
			{!surveyComplete ? <Modal.Header>
				{!showAcknowledgement ? <label className="section"><strong><small>Section {section}</small></strong><br />{questions[section - 1].name}</label> : <label className="section">Review & Submit<strong></strong></label>}

				<Button
					variant=""
					className="close"
					onClick={onCloseFrom}
				>
					<span>&times;</span>
				</Button>
			</Modal.Header> : null}
			<Modal.Body id="qaqcBody">

				{showQuestions ? <small className="text-muted ">Question {current + 1} of {questions[section - 1].questions.length}</small> : null}
				<div id="qaqc" className="survey text-center">
					{showAcknowledgement ? <><Acknowledgement setPoints1={setPoints1} setPoints2={setPoints2} setClearSignature={setClearSignature} clearSignature={clearSignature} finalData={finalData} setReadySubmit={setReadySubmit} setAddiData={setAddiData} formData={formData} setPdfData={setPdfData} setIsConfirmSignature={setIsConfirmSignature} onDone={() => { setShowAcknowledgement(false) }} /></> : null}
					{surveyComplete ? <><Complete onClone={startClone}  onSubmitResult={onSubmitResult} finalData={finalData} onDone={() => { setShowAcknowledgement(false); toggleModal(false); setSurveyComplete(false); setSection(1); setCurrent(1); setShowForm(true); setReadySubmit(false); setIsConfirmSignature(false); getSubmittedData() }} /></> : null}
					{!showAcknowledgement && !surveyComplete ?
						<div className="survey-content">
							{showForm ? (
								<Form
									canStart={canStart}
									data={formData}
									handleChange={updateData}
								/>
							) : null}
							
							{showQuestions ? (
								<Questions
									current={current}
									section={section - 1}
								/>
							) : null}

						</div> : null}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="row w-100">
					<div className="col col-6 text-left p-0">
						{showQuestions && current >= 0 ? <Button
							className="btn btn-link text-dark"
							onClick={() => {
								handlePageChange(-1);
							}}

						>
							Previous
						</Button> : null}
					</div>
					<div className={"col col-" + (!showQuestions ? "12" : "6") + " text-right p-0"}>
						{showAcknowledgement && canStart && <Button
							onClick={() => {
								setShowAcknowledgement(false);
								setSurveyComplete(true);
								onSubmitResult(finalData);
							}}
							className="w-100"
							variant="success"
							// disabled={!canStart || !readySubmit}
							disabled={!isConfirmSignature}
						>
							Submit Results
						</Button>}
						{/* {showAcknowledgement&&canStart&&!readySubmit&&<div className="row">
				<div className="col-12 col-sm-6 mt-3">
					<Button className="w-100 btn-secondary" 
					onClick={() => {
						setClearSignature(true);
						// inspectorSignatureRef.current.clear();
						// operatorSignatureRef.current.clear();
						setIsConfirmSignature(false);}
					}
					>
					Clear Signature
					</Button>
				</div>                
				<div className=" col-12 col-sm-6 mt-3">
					<Button 
					className="w-100 btn-secondary" 
					disabled = {isConfirmSignature?false:true} 
					// onClick = {confirmSignature}
					onClick={() => setReadySubmit(true)}
					>
					Confirm Signature
					</Button>
				</div>
				</div>} */}
						{showForm ? <Button
							disabled={!canStart}
							onClick={() => {
								setShowForm(false);
								setShowQuestions(true);
								setCurrent(0);
								setSection(2);
							}}
							className="w-100"
							variant="primary"
						>
							Start
						</Button> : null}

						{showQuestions ? <Button
							onClick={() => {
								handlePageChange(1);
							}}
							className="w-100"
							variant="primary"
							disabled={!canStart}
						>
							Next
						</Button> : null}
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default QualityControlModal;
