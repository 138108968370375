
const GlobalSearchBar = ({user, toggle, profile, onProfile}) => { 

return <li className="nav-item">
                        <div className="input-group search-area d-lg-inline-flex d-none">
                           <input
                              type="text"
                              className="form-control"
                              placeholder="Search something here..."
                           />
                           <div className="input-group-append">
                              <span className="input-group-text">
                                 <i className="flaticon-381-search-2"></i>
                              </span>
                           </div>
                        </div>
                     </li>;
}

export default GlobalSearchBar;