/* eslint-disable */
import { Link, Redirect } from "react-router-dom";
import { logOut } from "../../../utils/firebase";

const HeaderUserMenu = ({user, toggle, profile, onProfile}) => { 
 
   return user ? (
     <li
       className={`nav-item dropdown header-profile ${
         toggle === "profile" ? "show" : ""
       }`}
       onClick={() => onProfile()}
     >
       <a
         className="nav-link"
         href="#user"
         role="button"
         data-toggle="dropdown"
       >
         {/* <div className="small text-right">
           {user?.displayName}
           <div style={{opacity:0.6, fontWeight:"", fontSize:12}} className="opacity-5 outline smaller">
             {user.isAdmin
               ? "Administrator"
               : user.Plan
               ? user.role
               : "Administrator"}
           </div>
         </div> */}
         {/* {user?.photoURL ? (
           <img
             src={
   (user?.photoURL ?? user?.profile.photoURL ?? user.profile.PhotoURL) || "https://i.imgur.com/uDytaVG.png"
             }
             width="20"
             alt=""
           />
            ) : null} */}
         <div className="header-info">
           <span className="text-black">{user?.displayName}</span>
           <p className="fs-12 mb-0 text-black">
             {user.isAdmin
               ? "Administrator"
               : user.Plan
               ? user.role
               : "Administrator"}
           </p>
         </div>
       </a>
       <div
         className={`dropdown-menu dropdown-menu-right p-4 ${
           toggle === "profile" ? "show" : ""
         }`}
       >
         <Link
           to="/app-profile"
           className="dropdown-item ai-icon text-dark d-none "
         >
           <svg
             id="icon-user1"
             xmlns="http://www.w3.org/2000/svg"
             className="text-primary"
             width="18"
             height="18"
             viewBox="0 0 24 24"
             fill="none"
             stroke="currentColor"
             strokeWidth="2"
             strokeLinecap="round"
             strokeLinejoin="round"
           >
             <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
             <circle cx="12" cy="7" r="4"></circle>
           </svg>
           <span className="ml-2">Profile </span>
         </Link>
         <button
           to="/login"
           onClick={logOut}
           className="dropdown-item ai-icon text-dark"
         >
           <svg
             id="icon-logout"
             xmlns="http://www.w3.org/2000/svg"
             className="text-danger"
             width="18"
             height="18"
             viewBox="0 0 24 24"
             fill="none"
             stroke="currentColor"
             strokeWidth="2"
             strokeLinecap="round"
             strokeLinejoin="round"
           >
             <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
             <polyline points="16 17 21 12 16 7"></polyline>
             <line x1="21" y1="12" x2="9" y2="12"></line>
           </svg>
           <span className="ml-2">Logout </span>
         </button>
       </div>
     </li>
   ) : (
     ""
   );
}

export default HeaderUserMenu;